* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  .img-header{
    display:flex; 
    margin-top: -142px;
    margin-left: -150px;
    width:222vh;
  
}

  .text-inimage{
    box-sizing: border-box;
    display: grid;
    text-align: left;
    margin-top: -196px;
    margin-bottom: 59px;


    width: 400px;
    height: 70px;
    font-family:  Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 80px;

    color: #FFFFFF;

    text-shadow:  2px 5px 2px rgba(0, 0, 0, 0.25);

   
    
} 

  .text-inimage2{
    box-sizing: border-box;
    display: grid;
    text-align: left;
    margin-top: -47px;
    margin-bottom: 82px;

    width: 900px;

    font-family:  Poppins;
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 80px;
         
   
    color: #FFFFFF;

    
    text-shadow:  2px 5px 2px rgba(0, 0, 0, 0.25);
  } 
}
@media (max-width: 992px){
  .img-header{
    display: grid; 
    max-width: inherit;
    max-height: inherit;
    height: inherit;
    width: inherit;
    object-fit: cover;
    margin-top: -59px;
    margin-left: -0px;
}
.text-inimage{
  box-sizing: border-box;
  display: grid;
  text-align: left;
  margin-top: -108px;
  margin-bottom: 34px;
  margin-left:1px;
  height: 10px;
  font-family:  Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 40px;
  color: #FFFFFF;
  text-shadow:  2px 5px 2px rgba(0, 0, 0, 0.25);

} 

.text-inimage2{
  box-sizing: border-box;
  display: grid;
  text-align: left;
  margin-top: 1px;
  margin-bottom: 64px;

  margin-left:1px;
  width: 200px;
  height: 20px;
  font-family:  Poppins;
  font-style: normal;
  font-weight: 100;
  font-size: 3rem;
  line-height: 40px;
  color: #FFFFFF;
  text-shadow:  2px 5px 2px rgba(0, 0, 0, 0.25);
} 
}
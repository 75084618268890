@import "../../theme/variables.scss";

.business-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;

  @media only screen and (max-width: 992px) {
    display: none;
  }

  //Seccion 1

  .div-seccion-one {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 80px;
    margin: 5% 0;

    .div-text {
      width: 50%;
      margin-top: 5%;

      .title {
        font-family: "Poppins-Bold";
        font-weight: 700;
        font-size: 48px;
        line-height: 124%;
        color: #303133;
        margin-bottom: 24px;
        width: 675px;
        height: 105px;
      }
    }

    .img {
      width: 50%;
      margin-top: -2%;

      .img-one {
        width: 100%;
        min-width: 600px;
      }
    }

    .item {
      gap: 10px;
    }
  }

  //Sección 2
  .div-seccion-two {
    margin-top: -8%;

    .title-comen {
      height: 60px;

      margin-top: -10%;

      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 124%;
      text-align: center;
      color: #303133;

      position: absolute;
    }

    .div-tres-secciones {
      position: absolute;
      display: flex;
      flex-direction: row;
      gap: 100px;
      margin-top: 2%;

      justify-content: center;

      .cuadro-uno {
        width: 316px;
        height: 401px;
        left: calc(50% - 316px / 2 - 456px);
        top: calc(50% - 401px / 2 + 55.5px);
        filter: drop-shadow(10px 10px 25px #d6d6d8);
        background: white;
        border-radius: 20px !important;
        padding: 41px 43px 140px 35px;

        .title-icon {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .number {
            .number-text {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 700;
              font-size: 45px;
              line-height: 124%;
              color: #1e428a;

              @media (max-width: 768px) {
                padding-left: 20px;
              }
            }
          }
        }

        .text {
          display: flex;
          flex-direction: column;

          .subtitle {
            width: 238px;
            height: fit-content;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 700;
            font-size: 28px;
            line-height: 124%;
            color: #1e428a;
            flex: none;
            order: 1;
            flex-grow: 0;

            @media (max-width: 768px) {
              padding-left: 20px;
            }
          }

          .text-one {
            width: 232px;
            height: 87px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 146%;
            color: #2b333f;
            flex: none;
            order: 2;
            flex-grow: 0;
            margin-top: 5px;

            @media (max-width: 768px) {
              padding-left: 20px;
            }
          }
        }
      }

      .cuadro-dos {
        width: 316px;
        height: 401px;
        left: calc(50% - 316px / 2 - 456px);
        top: calc(50% - 401px / 2 + 55.5px);
        filter: drop-shadow(10px 10px 25px #d6d6d8);
        background: white;
        border-radius: 20px !important;
        padding: 41px 43px 140px 35px;

        .title-icon {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .number {
            .number-text {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 700;
              font-size: 45px;
              line-height: 124%;
              color: #1e428a;

              @media (max-width: 768px) {
                padding-left: 20px;
              }
            }
          }
        }

        .text {
          display: flex;
          flex-direction: column;

          .subtitle {
            width: 238px;
            height: fit-content;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 700;
            font-size: 28px;
            line-height: 124%;
            color: #1e428a;
            flex: none;
            order: 1;
            flex-grow: 0;

            @media (max-width: 768px) {
              padding-left: 20px;
            }
          }

          .text-one {
            width: 232px;
            height: 87px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 146%;
            color: #2b333f;
            flex: none;
            order: 2;
            flex-grow: 0;
            margin-top: 5px;

            @media (max-width: 768px) {
              padding-left: 20px;
            }
          }
        }
      }

      .cuadro-tres {
        width: 316px;
        height: 401px;
        left: calc(50% - 316px / 2 - 456px);
        top: calc(50% - 401px / 2 + 55.5px);
        filter: drop-shadow(10px 10px 25px #d6d6d8);
        background: white;
        border-radius: 20px !important;
        padding: 41px 43px 140px 35px;

        .title-icon {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .number {
            .number-text {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 700;
              font-size: 45px;
              line-height: 124%;
              color: #1e428a;

              @media (max-width: 768px) {
                padding-left: 20px;
              }
            }
          }
        }

        .text {
          display: flex;
          flex-direction: column;

          .subtitle {
            width: 238px;
            height: fit-content;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 700;
            font-size: 28px;
            line-height: 124%;
            color: #1e428a;
            flex: none;
            order: 1;
            flex-grow: 0;

            @media (max-width: 768px) {
              padding-left: 20px;
            }
          }

          .text-one {
            width: 232px;
            height: 87px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 146%;
            color: #2b333f;
            flex: none;
            order: 2;
            flex-grow: 0;
            margin-top: 5px;

            @media (max-width: 768px) {
              padding-left: 20px;
            }
          }
        }
      }
    }

    .linea-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .linea {
        margin-top: 45%;
      }

      .button {
        margin-top: 45%;
        margin-left: 2%;
        margin-bottom: 1%;
      }
    }
  }

  //Sección 3
  .div-seccion-three {
    height: 70vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 80px;
    margin: 2% 0;

    .div-text {
      width: 50%;

      .title {
        font-family: "Poppins-Bold";
        font-weight: 700;
        font-size: 48px;
        line-height: 124%;
        color: #303133;
        margin-bottom: 24px;
        width: 675px;
        height: 105px;

        .span {
          color: #204694;
          font-family: "Poppins-Bold";
          font-weight: 700;
          font-size: 48px;
          line-height: 124%;
        }
      }

      .text-online {
        width: 100%;
        height: 175px;

        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        color: #2b333f;
      }
    }

    .img {
      width: 50%;

      .img-three {
        width: 100%;
        min-width: 600px;
      }
    }
  }

  //Sección 4
  .div-seccion-four {
    height: 70vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 80px;

   

    .div-text {
      width: 60%;

      .title {
        font-family: "Poppins-Bold";
        font-weight: 700;
        font-size: 48px;
        line-height: 124%;
        color: #303133;
        margin-bottom: 24px;
        width: 675px;
        height: 105px;

        .span {
          color: #204694;
          font-family: "Poppins-Bold";
          font-weight: 700;
          font-size: 48px;
          line-height: 124%;
        }
      }

      .text-online {
        width: 86%;
        height: 175px;

        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        color: #2b333f;
      }
    }

    .img {
      width: 50%;
      margin-right: -10%;
      .img-four {
        width: 50%;
        min-width: 50px;
        height: 400px;
      }
    }
  }
  //Sección 5
  .div-seccion-five {
    height: 80vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 80px;

    margin-top: -5%;

    .div-text {
      width: 60%;
      margin-top: 10%;

      .title {
        font-family: "Poppins-Bold";
        font-weight: 700;
        font-size: 48px;
        line-height: 124%;
        color: #303133;
        margin-bottom: 24px;
        width: 675px;
        height: 105px;

        .span {
          color: #204694;
          font-family: "Poppins-Bold";
          font-weight: 700;
          font-size: 48px;
          line-height: 124%;
        }
      }

      .text-online {
        width: 86%;
        height: 175px;

        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        color: #2b333f;
      }
    }

    .img {
      width: 50%;
      margin-top: -10%;


      .img-five {
        width: 100%;
        min-width: 100px;
     
      }
    }
  }
  //Sección 6
  .div-seccion-six {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 80px;
    margin-bottom: -17%;
 

    .div-text {
      width: 50%;
  margin-top: 10%;
      .title {
        font-family: "Poppins-Bold";
        font-weight: 700;
        font-size: 48px;
        line-height: 124%;
        color: #303133;
        
        width: 675px;
        height: 105px;

        .span {
          color: #204694;
          font-family: "Poppins-Bold";
          font-weight: 700;
          font-size: 48px;
          line-height: 124%;
        }
      }

      .text-online {
        width: 100%;
        height: 175px;
        margin-top: -5%;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        color: #2b333f;
      }
    }

    .img {
      width: 50%;
      margin-top: -12%;
      .img-six {
        width: 100%;
        min-width: 600px;
      }
    }
  }

}

body {
  overflow-x: hidden !important;
}

.bg__dark {
  background-color: #0c1a37;
  z-index: 2;

  .paragraph {
    font-family: "Poppins";
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: white;
    margin: 0;
  }

  .section {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .title-logo {
      padding: 0;
    }

    .title {
      font-family: "Poppins-semiBold-700";
      font-size: 60px;
      line-height: 150%;
      color: white;

      span {
        font-family: "Poppins-semiBold-700";
        color: #ffc627;
      }
    }
  }
}

.business.fooTemp__container {
  background-color: #0c1a37;

  .foo__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .paragraph {
      margin: 24px 0;
    }

    .text-white {
      font-weight: 400 !important;
      font-size: 18px !important;
      line-height: 140% !important;
    }
  }
}

.business.fooTemp__container {
  background-color: #0c1a37;

  @media only screen and (max-width: 768px) {
    margin-top: -5%;
  }

  .foo__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 67px;

    @media only screen and (max-width: 768px) {
      padding: 29px 44px;
    }

    .title-register {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 110%;

      @media only screen and (max-width: 768px) {
        width: 110%;
      }

      .register {
        font-family: "Poppins-bold";
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 150%;
        text-align: center;
        justify-content: center;
        align-items: center;
        color: #ffffff;

        @media only screen and (max-width: 768px) {
          font-family: "Poppins-bold";
          font-style: normal;
          font-weight: 600;
          font-size: 30px;
          line-height: 150%;
          /* identical to box height, or 48px */

          text-align: center;

          color: #ffffff;
        }
      }

      .free {
        font-family: "Poppins-bold";
        font-style: normal;
        font-weight: 600;
        font-size: 54px;
        line-height: 150%;
        text-align: center;
        color: #ffc629;

        @media only screen and (max-width: 768px) {
          font-family: "Poppins-bold";
          font-style: normal;
          font-weight: 700;
          font-size: 35px;
          line-height: 150%;
          /* identical to box height, or 48px */

          text-align: center;
          text-transform: capitalize;
          width: 110%;
          /* Yellow */

          color: #ffc629;
        }
      }
    }

    .paragraph {
      margin: 24px 0;
    }

    .text-white {
      font-weight: 400 !important;
      font-size: 18px !important;
      line-height: 140% !important;
    }

    .parrafo-register {
      margin-top: 12px;
      margin-bottom: 52px;
      align-items: center;
      justify-content: center;
      width: 600px;

      @media only screen and (max-width: 768px) {
        margin-bottom: 32px;
        width: 324px;
      }

      .text-register {
        font-family: "Poppins-bold";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        margin-right: 5%;
        text-align: center;

        color: #ffffff;

        @media only screen and (max-width: 768px) {
          font-family: "Poppins-bold";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 150%;
          /* or 18px */

          text-align: center;

          color: #ffffff;
        }

        .text-register-two {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 150%;
          /* or 18px */

          text-align: center;

          color: #ffffff;
        }
      }
    }

    .button-register {
      margin-bottom: 67px;

      .text-register {
        text-decoration: none;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;

        letter-spacing: -0.02em;

        color: #163065;
      }
    }
  }
}

// responsive

@media (max-width: 700px) {
  .last__section_product {
    height: auto;
    padding: 100px 20px;
    background-color: #121a2c;

    .foo__content {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-left: 0px;
    }

    .images {
      position: inline-block;
    }
  }
}

.video-home {
  width: 100%;
  margin-bottom: 2.09rem;
}

/* .last-section-business{
  background-color: #E9ECF3;
} */

.text-business {
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  letter-spacing: -0.02em;

  color: #ffffff;

  &:hover {
    color: #cddaf4;
  }
}

.CookieConsent {
  align-items: baseline;
  background: #204694;
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  left: 0px;
  position: fixed;
  width: 100%;
  z-index: 999;
  bottom: 0px;
  font-family: "Poppins" !important;
}

.CookieConsent a {
  cursor: none;
  padding-left: 1vh !important;
  color: #ffc629 !important;
  list-style: none !important;
  text-decoration: none !important;
}


.kXteup {
  display: none;
}

.Slxdj {
  display: none;
}

.ezKHIn {
  background-color: #ffdd7d !important;
  border: none !important;
  box-shadow: none !important;
}

.cVXxbE {
  background-color: #ffdd7d !important;
  border: none !important;

  &.rec-dot_active {
    width: 27px !important;
    height: 9px !important;
    border-radius: 30px;
  }
}

.carousel-wrapper {
  margin-top: 10%;

  .cuadro-uno {
    width: 300px;
    height: 362px;
    left: calc(50% - 316px / 2 - 456px);
    top: calc(50% - 401px / 2 + 55.5px);
    box-shadow: (10px 10px 25px #d6d6d8);
    background: white;
    border-radius: 20px !important;
    padding: 41px 43px 140px 40px;
    margin-bottom: 50px;
    margin-top: 50px;

    .title-icon {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .number {
        .number-text {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 700;
          font-size: 45px;
          line-height: 124%;
          color: #1e428a;
        }
      }
    }

    .text {
      display: flex;
      flex-direction: column;

      .subtitle {
        width: 238px;
        height: fit-content;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 124%;
        color: #1e428a;
        flex: none;
        order: 1;
        flex-grow: 0;
      }

      .text-one {
        width: 232px;
        height: 87px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 146%;
        color: #2b333f;
        flex: none;
        order: 2;
        flex-grow: 0;
        margin-top: 5px;
      }
    }
  }

  .button-movil {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 20px;
  }
}

.hGRYOm {
  display: none;
}

.hGPzOw {
  display: none;
}
@import "../../theme/variables.scss";
.container-inpt {
  width: 273px;
  height: 64px;
  .inpt {
    background-color: #fff;
    width: 273px;
    height: 64px;
    border: none;
    outline: none;
    border-radius: 6px;
    text-indent: 17px;
    font-size: 18px;
    margin: 8px 0;
    &::-webkit-input-placeholder {
      font-family: "Montserrat-regular";
      font-size: 18px;
      color: $text-gray;
    }
    &::-moz-placeholder {
      font-size: 18px;
      font-family: "Montserrat-regular";
      color: $text-gray;
    } /* firefox 19+ */
    &:-ms-input-placeholder {
      font-size: 18px;
      font-family: "Montserrat-regular";
      color: $text-gray;
    } /* ie */
    &:-moz-placeholder {
      font-size: 18px;
      font-family: "Montserrat-regular";
      color: $text-gray;
    }
  }
}

@import "theme/variables.scss";

.slider img {
  width: 55%;
  height: 200px;
  margin: 110px auto;
  max-width: 80%;
}
.slide {
  transform: scale(1.5);
  transition: transform 300ms;
  // opacity: 0.5;
  opacity: 1;
  position: relative;

  &.activeSlide {
    transform: scale(1.5);
    opacity: 1;
    .img {
      max-height: 607px;
    }
  }
  &:focus {
    outline: none;
    border: none;
  }
}
.arrow {
  position: absolute;
  cursor: pointer;
  z-index: 10;
  svg {
    circle {
      fill: $secondary-color;
    }
    path {
      fill: $background-gray-dark;
    }
  }
}
.prev {
  top: -76px;
  right: 70px;

  &:hover {
    svg {
      circle {
        fill: $primary-color;
      }
      path {
        fill: $secondary-color;
      }
    }
  }
}

.next {
  top: -76px;
  right: 0;

  &:hover {
    svg {
      circle {
        fill: $primary-color;
      }
      path {
        fill: $secondary-color;
      }
    }
  }
}

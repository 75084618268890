@import "../../theme/variables.scss";
.wrapper-animation {
  position: relative;
  width: 100%;
  margin-top: 74px;
  .section-animation {
    height: 100vh;
    width: 44%;
    padding-left: 80px;
    display: flex;
    flex-direction: column;
    &.y-center {
      justify-content: center;
    }
  }
  .col-fixed {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 50%;
    display: flex;
    align-items: flex-end;
    z-index: -1;

    &.end-animation {
      position: absolute;
      top: inherit;
      bottom: 0;
    }

    .resource-animation {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      transition: opacity 1s;

      &.show {
        opacity: 1;
      }
      &.hidden {
        opacity: 0;
      }

      .lottie {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }
}

.section-animation .title-logo {
  padding-bottom: 3vh;
}
@media only screen and (max-width: 900px) {
  .resource-animation {
    width: 100%;
  }
  .section-animation {
    padding: 2px 0px;
  }
  .resource__responsive {
    width: 100%;
  }

  .lottie div {
    width: 100% !important;
  }
}

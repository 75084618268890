@import "./variables.scss";
@font-face {
  font-family: "Poppins-semiBold";
  font-style: normal;
  font-weight: 600;
  src: local("Poppins-SemiBold"),
    url("../assets/fonts/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-semiBold-700";
  font-style: normal;
  font-weight: 700;
  src: local("Poppins-SemiBold"),
    url("../assets/fonts/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  font-style: normal;
  font-weight: bold;
  src: local("Poppins-Bold"),
    url("../assets/fonts/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins-Regular"),
    url("../assets/fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-medium";
  font-style: normal;
  font-weight: 500;
  src: local("Poppins-Medium"),
    url("../assets/fonts/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Light";
  font-style: normal;
  font-weight: 400;
  src: local("Poppins-Light"),
    url("../assets/fonts/Poppins-Light.ttf") format("truetype");
}

p,
span,
a {
  font-family: "Poppins";
  // max-width:28.4rem;
}
b,
strong {
  font-weight: 700;
}

.offcanvas {
  transition: transform 0.5s ease-out !important;
  max-width: calc(100% - 109px);
}

.offcanvas-backdrop.show {
  opacity: 0.25;
}
.offcanvas-end {
  border-left: 1px solid #eeeff1;
}

.container-responsive {
  display: none;
  @media only screen and (max-width: 992px) {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100vw;
    /*  background-color: red; */
    .section-mobile {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &.dark {
        background-color: #121a2c;
      }
    }
    .section-image {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      img {
        width: 100%;
      }
    }
  }
}

.default-title {
  font-family: "Poppins-Bold";
  font-weight: 700;
  font-size: 48px;
  line-height: 124%;
  color: #303133;
  margin-bottom: 24px;
  span {
    color: #204694;
    font-family: "Poppins-Bold";
    font-weight: 700;
    font-size: 48px;
    line-height: 124%;
  }
  &[data-variation="less-text"] {
    line-height: 124%;
  }
  &.dark {
    color: #ffffff;
    span {
      color: #ffc627;
    }
    &.who{
      @media only screen and (max-width: 992px) {
        margin-top: 40px;
        span {
          
          font-size: 1.8rem;
        }
      }
    }
  }
  @media only screen and (max-width: 992px) {
    width: 80%;
    text-align: center;
    font-size: 2.1rem;
    span {
      font-size: 2.1rem;
    }
  }
}

.default-subTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 146%;
  color: #204694;
  margin-bottom: 24px;
  &.dark {
    color: #ffd152;
  }
  @media only screen and (max-width: 992px) {
    width: 80%;
    text-align: center;
    font-size: 1.5rem;
  }
}

.default-paragraph {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 146%;
  color: #303133;
  margin-bottom: 24px;
  &.dark {
    color: white;
  }
  @media only screen and (max-width: 992px) {
    /* text-align: center; */
    width: 80%;
  }
}

.default-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  &.dark {
    .image {
      color: white !important;
    }
    .text {
      color: white !important;
    }
  }
  .item {
    display: flex;
    gap: 14px;
    .image {
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #303133;
    }
    .text {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 146%;
      color: #303133;
    }
  }
  @media only screen and (max-width: 992px) {
    width: 80%;
  }
}

.default-span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 146%;
  color: #2b333f;
  margin-bottom: 24px;
  &.dark {
    color: white;
  }
  @media only screen and (max-width: 992px) {
    /* text-align: center; */
    width: 80%;
  }
}
.title-logo {
  @media only screen and (max-width: 992px) {
    img {
      width: 24rem;
      margin: 40px 0;
    }
  }
}
.default-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 20px;
  width: 366px;
  height: 59px;
  background: #204694;
  border-radius: 30px;
  color: white;
  cursor: pointer;
  a {
    text-decoration: none;
    color: white;
  }
  &.dark {
    background: #cddaf4;
    a {
      text-decoration: none;
      color: #163065;
    }
  }
}


html, body {
  @media only screen and (max-width: 768px) {
    overflow-x: hidden !important;
  }
}
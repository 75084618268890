.item-check {
  display: flex;
  align-items: flex-start;
  .ic-item-check {
    height: 100%;
    width: 24px;
    margin: 0 10px;
  }
}
.textcolor {
  color: red;
}

.main-footer2 {
  width: 100%;
  color: #fff;
  background-color: #394454;
  position: inherit;
  padding: 74px 106px 90px 106px;
  justify-content: space-between;


  &.relative {
    position: relative;
    top: auto;
  }

  .containera {
    display: grid;
    grid-template-columns: 1fr 3fr;
    justify-content: space-between;
    min-height: 274px;
    width: 100%;

    .containerlogo-footer {
      width: 10%;
      

    }
  }

  .cont-barra {
    display: grid;
    /*grid-template-columns: auto 1fr 1fr 1fr;*/
    /*height:400px;*/
    grid-template-columns: repeat(4, 1fr);
    margin-left: -10%;
  }
  .titles {
    text-align: left;
    width: 100%;
    color: #fff;
    font-family: "Poppins-bold";
    font-size: medium;
  }

  .mastercard {
    width: 100%;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    display: flex;
    align-items: center;
  }
  .lines {
    line-height: 300%;
    list-style: none;
    text-align: left;
    width: 100%;
    color: #fff;
    font-family: "Poppins-bold";
    font-size: medium;
    justify-content: center;
  
    a {
      color: #fff;
      text-decoration: none;
    }
    .lines-two {
      line-height: 300%;
      list-style: none;
      text-align: left;
      width: 100%;
      color: #fff;
      font-family: "Poppins-bold";
      font-size: medium;
      justify-content: center;
      a {
        color: #fff;
        text-decoration: none;
      }
    }

    .lines-three {
      line-height: 300%;
      list-style: none;
      text-align: left;
      width: 100%;
      color: #fff;
      font-family: "Poppins-bold";
      font-size: medium;
      justify-content: center;
      a {
        color: #fff;
        text-decoration: none;
      }
    }
    @media only screen and (max-width: 768px) {
      margin-top: -4%;
      margin-left: 0.5%;
    }
  }
  .mastercard {
    margin-top: -14%;
    width: 120%;
    @media only screen and (max-width: 768px) {
      margin-left: 11%;
      width: 80%;
      margin-top: 5%;
    }

    .mastercard-lines {
      line-height: 300%;
      list-style: none;
      text-align: left;
      width: 100%;
      color: #fff;
      font-family: "Poppins-bold";
      font-size: medium;

      .text-mastercard {
        font-size: 10px;
        text-align: left;
        @media only screen and (max-width: 768px) {
          font-size: 12px;
          letter-spacing: 0.1em;
        }
      }
    }
  }

  .lines2 {
    line-height: 300%;
    list-style: none;
    text-align: center;
    width: 100%;
    color: #fff;
    font-family: "Poppins-bold";
    font-size: medium;
    justify-content: center;
    a {
      color: #fff;
      text-decoration: none;
    }
  }

  .hijo {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 28px;
  }

  .media {
    width: 100%;
    display: flex;
  }

  img {
    width: 30px;
    margin: 2px;
  }
  img.grande {
    width: 154.45px;
    height: 44px;
    
  }
  img.normal {
    width: 154.45px;
    height: 44px;
  }
  .img-fluid {
    margin-top: 6px;
  }
}

.hijo2 {
  .lines2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */

    display: flex;
    align-items: center;
    margin-top: 2%;
    color: #637692;
  }
}

@media (max-width: 992px) {
  .main-footer2 {
    position: inherit;

    .containera {
      grid-template-columns: 1fr;
      
  @media only screen and (max-width: 768px) {
    padding: 22px;
  }
      .containerlogo-footer {
        display: flex;
        justify-content: center;
        margin: 40px 0;
        @media only screen and (max-width: 768px) {
          margin-left: 15%;
        }
      }
    }
  }
}
@media only screen and (max-width: 800px) {
  .main-footer2 {
    padding: initial;
    .cont-barra {
      grid-template-columns: repeat(1, 1fr);
    }

    .titles {
      display: flex;
      padding-left: 20px;
    }
  }
}

@import "../../../theme/variables.scss";

.form {
  position: relative;
  min-height: 120vh;
  padding-top: 80px;
  padding-bottom: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #e9ecf3;
  .paper-image {
    position: absolute;
    left: 50px;
    top: 40%;
    z-index: 3;
    @media screen and (max-width: 992px) {
      display: none;
    }
    img {
      width: 138px;
      height: 138px;
    }
  }
  .paper-image-2 {
    position: absolute;
    top: 10%;
    right: 50px;
    z-index: 3;
    @media screen and (max-width: 992px) {
      display: none;
    }
  }
  .form-body {
    justify-content: center;
    align-items: center;
    .encabezado {
      width: 100%;
   
      .default-title {
        width: 90%;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-left: 5%;
      }
      .default-subTitle {
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }

    .form-complet{
      display: flex;
      align-items: center;
      justify-content: center;

      .inputform {
        justify-content: center;
        align-items: center;
        flex-direction: column;
  
        &.error {
          input {
            border: 2px solid red;
          }
        }
  
    
      }
    }
 
  }
}
// .suppliers-div {
//   margin-left: 21% !important;
// }
.continput {
  margin-top: 17px;
  margin-bottom: 17px;
  width: 100%;
  height: 60px;
  border-radius: 35px;
  border: none;

  justify-content: center;
  display: flex;
  &.error {
    input {
      border: 2px solid red;
    }
  }
}

input {
  border: none;
  align-items: center;
  width: 545px;
  height: 60px;
  border-radius: 30px;
  background-color: #fff;
  justify-content: center;
  padding: 0px 32px;
  margin-bottom: 20px;
  z-index: 5px;
  &::placeholder {
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    /* or 27px */

    display: flex;
    align-items: center;

    color: #c2c2c2;
  }
  &:focus-visible {
    border: 2px solid #2684ff;
    outline: none;
    box-shadow: 10px #2684ff;
  }
}

.error-msg {
  margin-left: 3%;
  width: 300px;
}


.button-unete {


  .bubbly-button {
    width: 100%;
  }
}




/*modal*/
.swal2-popup {
  display: none;
  position: relative;
  box-sizing: border-box;
  grid-template-columns: minmax(0, 100%);
  width: 32em;
  max-width: 100%;
  padding: 0 0 1.25em;
  border: none;
  border-radius: 5px;
  background: #204694 !important;
  color: #fff !important;
  font-family: inherit;
  font-size: 1rem;
}

.swal2-image {
  width: 50%;
  margin: 0em auto 0em;
  height: 100%;
}
.swal2-container.swal2-center > .swal2-popup {
  grid-column: 2;
  grid-row: 2;
  align-self: center;
  justify-self: center;
  font-family: "Poppins";
}
.swal2-html-container {
  margin: 1px 1.6em 23px;
  font-family: "Poppins";
}


.Toastify__close-button {
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  align-self: flex-start;
  margin-top: 2%;
  margin-right: 3%;
}

.back-image {
  width: 100%;
  position: absolute;
  z-index: 1;
  background-image: url("../../../assets/Homev3/paperPlane.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-position-y: 55vh;
  background-position-x: 50vw;
  width: 100%;
  min-height: 90vh;

  filter: blur(3px);
}
.back-blur {
  position: absolute;
  z-index: 2;
  width: 100%;
  min-height: 90vh;

  background-color: rgba(255, 255, 255, 0.212);
}
@media only screen and (max-width: 768px) {



  .form-body {
    justify-content: center;
    align-items: center;
    .encabezado {
      margin-top: -85%;
      width: 100%;
      .default-title {
        width: 90%;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-left: 7%;
      }
      .default-subTitle {
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-left: 10%;
      }
    }

    .form-complet{
      display: flex;
      align-items: center;
      justify-content: center;

      .inputform {
        justify-content: center;
        align-items: center;
        flex-direction: column;
  
        &.error {
          input {
            border: 2px solid red;
          }
        }
  
    
      }
    }
  }
  input {
    border: none;
    align-items: center;
    width: 320px;
    height: 60px;
    border-radius: 30px;
    background-color: #fff;
    justify-content: center;
    padding: 0px 32px;
    margin-bottom: 20px;
    margin-left: 6%;
    z-index: 5px;
    &::placeholder {
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      /* or 27px */

      display: flex;
      align-items: center;

      color: #c2c2c2;
    }
    &:focus-visible {
      border: 2px solid #2684ff;
      outline: none;
      box-shadow: 10px #2684ff;
    }
  }
  .error-msg {
    margin-left: -22%;
    width: 300px;
  }

  .suppliers-div {
    margin-left: 5%;
  }

  .button-unete {
    margin-left: 5%;

    .bubbly-button {
      width: 100%;
    }
  }
  
  .Toastify__toast-container {
  .Toastify__toast-container--bottom-center {
    bottom: none !important;
    left: 50%;
    margin-top: -5%;
    transform: translateX(-50%);
    .toast-person{
      width: 100% !important;
    }
   
}}



  /*modal*/
.swal2-popup {
  display: none;
  position: relative;
  box-sizing: border-box;
  grid-template-columns: minmax(0, 100%);
  width: 32em;
  max-width: 100%;
  padding: 0 0 1.25em;
  border: none;
  border-radius: 5px;
  background: #204694 !important;
  color: #fff !important;
  font-family: inherit;
  font-size: 1rem;
}

.swal2-image {
  width: 50%;
  margin: 0em auto 0em;
  height: 100%;
}
.swal2-container.swal2-center > .swal2-popup {
  grid-column: 2;
  grid-row: 2;
  align-self: center;
  justify-self: center;
  font-family: "Poppins";
}
.swal2-html-container {
  margin: 1px 1.6em 23px;
  font-family: "Poppins";
}
}


.buttons-home{
  display: flex;
  gap: 5px;

}
$primary-color: #204694;
$secondary-color: #ffc629;
$text-white: #ffffff;
$text-white-dark: #f0f2ff;
$text-gray: #818999;
$text-gray-dark: #2e3138;
$background-gray-dark: #333333;
$background-yellow-light: #fafafa;
$black-color: #000000;
$content-text: #2e5dbd;
:export {
  primary-color: $primary-color;
  secondary-color: $secondary-color;
  text-white: $text-white;
  text-white-dark: $text-white-dark;
  black-color: $black-color;
  text-gray: $text-gray;
  text-gray-dark: $text-gray-dark;
  background-gray-dark: $background-gray-dark;
  background-yellow-light: $background-yellow-light;
  content-text: $content-text;
}



.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index) px);
  position: fixed;
  padding: 4px;
  width: 40%;
  max-width: 40%;
  min-width: 20%;
  
  box-sizing: border-box;
  color: #fff;
  margin-left: -20%;
  bottom: 0;


  @media only screen and (max-width: 768px) {

    z-index: var(--toastify-z-index);
    -webkit-transform: translate3d(0, 0, var(--toastify-z-index) px);
    position: fixed;
    padding: 4px;
    width: 65%;
    max-width: 65%;
    min-width: 65%;
    box-sizing: border-box;
    color: #fff;
    margin-left: 1%;
    margin-bottom: 200%;
  }

  .Toastify__toast-body > div:last-child {
    word-break: break-word;
    flex: 1 1;

    font-size: 15px;
    @media only screen and (max-width: 768px) {

      word-break: break-word;
      flex: 1 1;
  
      font-size: 15px;
    }
}

.Toastify__toast-icon {
  margin-inline-end: 10px;
  width: 40px;
  flex-shrink: 0;
  display: flex;
  @media only screen and (max-width: 768px) {
    margin-inline-end: 10px;
    width: 20px;
    flex-shrink: 0;
    display: flex;
  }
}
.Toastify__close-button {
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  align-self: flex-start;
  
  svg {
    fill: currentColor;
    height: 25px;
    width: 25px;
    margin-top: -55%;
    @media only screen and (max-width: 768px) {
      fill: currentColor;
      height: 16px;
      width: 16px; 
    margin-top: 5%;
  
    }
}
}

}



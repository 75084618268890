.row {
  display: grid;
  // height: 100vh;
  width: 100%;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 20px;
  padding: 0px 0px;
}
@media screen and (min-width: 992px) {
  //esto se cumple para pantallas mayores o iguales a 992px
  .row {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    &.full {
      padding: 0;
    }
    &.col-3 {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.Footer {
  background-color: #204694;
  width: 100%;
  height: 2px;
  display: flex;
  align-items: center;
  color: white;

  .section-footer {
    width: 100%;
    .section-footer-content {
      display: flex;
      padding: 0px 120px;
      justify-content: space-between;

      a {
        color: white;
        text-decoration: none;
      }
      .bold {
        font-weight: 600;
      }

      .link {
        font-size: 14px;
      }
      .title {
        font-size: 14px;
      }

      img {
        width: 30px;
        margin: 5px;
      }
    }
  }
}
.icon-wao {
  margin-top: 100px;
}

.social-icons {
  margin-top: 34px;
  display: flex;
  svg {
    margin-right: 16px;
  }
}

.derechos {
  margin-top: 65px;
}

.terminos {
  margin-top: 12px;
  // display: flex;

  span {
    font-family: "Poppins-medium";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin: 0px 10px 0px 0px;
    /* identical to box height */

    // display: flex;
    // align-items: center;

    color: #ffffff;
  }
}

// .small {
//   font-family: "Poppins-semiBold";
//   font-style: normal;
//   font-weight: 600;
//   font-size: 15px;
//   margin-bottom: 15px;
// }

.big {
  font-family: "Poppins-semiBold-700";
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
}

@media (max-width: 600px) {
  .footer {
    justify-content: center;
    padding-bottom: 30px;
    text-align: center;
  }
  .big {
    width: 300px;
  }
}

.derechos {
  font-family: "Poppins-semiBold";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #ffffff;
}

.accordion-terms {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 32px;
  margin-bottom: 12px;
  width: 579px;
  height: fit-content;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  @media only screen and (max-width: 768px) {
    width: 150%;
    
 
  }
  .header {
    display: flex;
    justify-content: space-between;
   
    .title {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 150%;
      color: #303133;
      &.active {
        color: #2f80ed;
      }
  
    }
  }
  .collapse__content {
    text-align: justify;
  }
}

@import "../../theme/variables.scss";

#mouse-effect {
  .content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    font-size: 22px;
    color: #33333387;
    font-family: "Montserrat-Medium";
    padding-left: 80px;

    .title{
      margin-bottom: 15px;
    }

    .text-box {
      cursor: pointer;
      z-index: 2;
      transition-duration: 0.5s;
      .title {
        font-size: 1.25rem;
        opacity: 0.3;
        margin: 0.8rem 0;
      }
      .description {
        display: none;
        font-family: "Montserrat-Light";
        font-size: 18px;
        max-width: 400px;
        margin-bottom: 20px;
      }

      &.active {
        .title {
          opacity: 1;
          animation: inEffectTitle 0.3s 1;
          animation-fill-mode: forwards;
        }
        .description {
          display: inline-block;
          color: #333;
          animation: inEffectDescription 0.8s 1;
          animation-fill-mode: forwards;
        }
      }
    }
    .bg-image {
      position: absolute;
      opacity: 0;
      top: 50%;
      left: 50%;
      z-index: 1;

      &.animation {
        transition-duration: 0.5s;
        animation: inBgImage 0.8s 1;
        animation-fill-mode: forwards;
      }
    }
  }
  .animation-left{
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    .img-animation-left{
      width: 100%;
      height: auto;
      position: relative;
      bottom: -6px;
    }
    
  }
}

@keyframes inEffectTitle {
  0% {
    font-size: 16px;
  }
  100% {
    font-size: 25px;
  }
}
@keyframes inEffectDescription {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes inBgImage {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.2);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

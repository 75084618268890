.transition-effect{
    position: absolute;
z-index: 1000;
background-color: #ffc629;
top: 0;
width: 50%;
height: 100%;
overflow: hidden !important;
border-radius: 100%;
display:none;
}


@media only screen and (max-width: 768px){
    .transition-effect{
        display:none;
    }
    
}
.card__logo_container {
  
  flex: 1;
  min-width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  margin: 10px 5px;

  &:hover {
    background-color: #ffc629;
    border: 1px solid #ffc629;
    border-radius: 12px;
  }

  .card__logo_img{
    width: 100%;
    object-fit: contain;
  }
}


//TABLE ONE

.table-global {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 5%;

  .title-global {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 35px;
    color: #000000;
    margin-bottom: 3%;
    @media (max-width: 768px) {
        font-size: 20px;
        margin-top: -15%;
    }
  }

  .div-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #204694;
    gap: 160px;
    width: 125%;
    max-width: 85%;
    padding-left: 15px;
    height: 96px;
    margin-left: 1.5%;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #ffffff;
    border-radius: 10px;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: row;
      gap: 32px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 23px;

      color: #ffffff;
    }

    .div-head-one {
      margin-left: 5.5%;
    }
  }
  .div-head-two {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #204694;
    gap: 200px;
    width: 125%;
    max-width: 85%;
    padding-left: 15px;
    height: 96px;
    margin-left: 1.5%;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #ffffff;
    border-radius: 10px;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: row;
      gap: 32px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 23px;

      color: #ffffff;
    }

    .div-head-one {
      margin-left: 5.5%;
    }
  }
  .div-head-three {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #204694;
    gap: 270px;
    width: 125%;
    max-width: 85%;
    padding-left: 15px;
    height: 96px;
    margin-left: 1.5%;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #ffffff;
    border-radius: 10px;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: row;
      gap: 32px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 23px;

      color: #ffffff;
    }

    .div-head-one {
      margin-left: 5.5%;
    }
  }
  .table-one-wrapper {
    width: 125%;
    max-width: 85%;

    margin-left: 1%;

    .table-body {
      text-align: left;

      .title {
        width: 20%;
        padding-left: 105px;
        text-align: left;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        color: #000000;
        @media (max-width: 768px) {
          padding-left: 30px;
          font-style: normal;
          font-weight: 700;
          font-size: 15px;
          line-height: 23px;
        }
      }
      .title-two {
        width: 100%;
        max-width: 25%;
        padding-left: 100px;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        color: #000000;
        @media (max-width: 768px) {
          padding-left: 30px;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 23px;
        }
      }
      .title-three {
        width: 30%;
        padding-left: 105px;
        text-align: left;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        color: #000000;
        @media (max-width: 768px) {
          padding-left: 30px;
          font-style: normal;
          font-weight: 700;
          font-size: 15px;
          line-height: 23px;
        }
      }
      .text-two {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        color: #000000;
        width: 20%;
        text-align: center;
        @media (max-width: 768px) {
          padding-left: 30px;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 23px;
        }
      }
      .text {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        color: #000000;
        @media (max-width: 768px) {
          font-size: 15px;
        }
      }
      .text-four {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        padding-left: 100px;
        color: #000000;
        text-align: justify;
        @media (max-width: 768px) {
          font-size: 15px;
          padding-left: 0px;
        }
      }

      .row-gray {
        background-color: #ededed;
      }
    }
  }
  .table-four-wrapper {
    width: 125%;
    max-width: 85%;

    @media (max-width: 768px) {
      width: 125%;
      max-width: 100%;
    }
    .table-body {
      text-align: left;

      .text-four {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        padding-left: 100px;
        color: #000000;
        text-align: justify;

        @media (max-width: 768px) {
          font-size: 15px;
          padding-left: 50px;
        }
      }
    }
  }
}

@import "../../theme/variables.scss";

.container-button {
  .button {
    width: 200px;
    &.small {
      width: 117px;
    }
    height: 64px;
    background-color: $primary-color;
    border: none;
    outline: none;
    border-radius: 6px;
    color: #fff;
    font-family: "Montserrat-regular";
    font-size: 16px;
    cursor: pointer;
    margin: 8px 0px;

    &:hover {
      background-color:#18346D;
      color: #FFF;
    }

    /***** Colores ****/
    &.secondary {
      background-color: $secondary-color;
      &:hover {
        background-color: $primary-color;
        color: #fff;
      }
    }
    &.gray {
      background-color: #f1f1f1;
      color: #000;
      &:hover {
        background-color: #e4e4e4;
        color: #000;
      }
    }
  }
}

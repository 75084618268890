body {
  transition: background 0.8s; /* .5s how long transitions shoould take */
}
.wrapper-wheel {
  height: 100vh;
  overflow-y: hidden;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;

  .wrapper-bullet {
    position: fixed;
    right: 40px;
    top: 50%;
    z-index: 3;

    .bullet {
      width: 10px;
      height: 10px;
      border-radius: 15px;
      margin: 5px 0;
      background-color: #818999;
      cursor: pointer;
      &.active {
        background-color: #2f80ed;
      }
    }
  }

  .section-wheel {
    display: flex;
    width: 50%;
    height: 100vh;
    align-items: center;
    scroll-snap-align: start;
    padding: 0 50px;

    .content {
      position: relative;
      width: 100%;
      height: 100%;
      .title-animated {
        animation: FadeOut 1s ease;
        animation-fill-mode: forwards;
      }
      .sub-animated {
        opacity: 0;
      }
      .text-animated {
        opacity: 0;
      }
      &.show {
        .title-animated {
          animation: FadeIn 0.5s ease;
          animation-delay: 0;
          animation-fill-mode: forwards;
        }
        .sub-animated {
          animation: FadeIn 0.5s ease;
          animation-delay: 1s;
          animation-fill-mode: forwards;
        }
        .text-animated {
          animation: FadeIn 0.5s ease;
          animation-delay: 1.5s;
          animation-fill-mode: forwards;
        }
      }
    }
  }

  .wrapper-image {
    position: fixed;
    height: 100vh;
    width: 50%;
    top: 0;
    right: 0;
    z-index: 2;
    .image-wheel {
      position: absolute;
      width: 85%;
      height: 100%;
      object-fit: contain;
      object-position: center bottom;
      opacity: 0;
      transition: opacity 1s;
      &.show {
        opacity: 1;
      }
    }
  }
}

@keyframes FadeIn {
  from {
    opacity: 0;
    transform: translate(0px, 24px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@keyframes FadeOut {
  from {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  to {
    opacity: 0;
    transform: translate(0px, 24px);
  }
}

@import "../../theme/variables.scss";
$fuschia: $primary-color;
$button-bg: $fuschia;
$button-text-color: #fff;
$baby-blue: #f8faff;

.bubbly-button {

  font-family: "Poppins";
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 20px;
width: fit-content;
  height: 59px;
  -webkit-appearance: none;
  appearance: none;
  background-color: $button-bg;
  color: $button-text-color;
  border-radius: 32px;
  border: none;
  cursor: pointer;
  position: relative;
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
  /*  box-shadow: 0 2px 25px rgb(32 70 148 / 78%); */
  &:hover {
    background: #163065;
    border: 2px solid#163065;
 
  }
  &.small-home {
    width: 180px;
@media only screen and (max-width: 768px) {
  width: 145px;
}
  }

  &.small {
    width: 180px;

    &:hover {
      background: #163065;
      border: 2px solid#163065;
      width: 182px;
 
    }

  }
  &.medium {
    width: 350px;
    font-size: 16px;
    &:hover {
      background: #163065;
      border: 2px solid#163065;
      font-size: 16px;
    }

  }
  &.large {
    width: 100%;
    font-size: 16px;
    &:hover {
      background: #163065;
      border: 2px solid#163065;
      font-size: 16px;
    }

  }
  &.light {
    background: #cddaf4;
    border: 2px solid #cddaf4;
    border-radius: 30px;
    color: #163065;
    width: fit-content;
    &:hover {
      background: #bcc6dc;
      border: 2px solid #bcc6dc;
    }
    &:active {
      transform: scale(0.9);
      background: #c7d6f3;
      box-shadow: 0 2px 25px rgb(32 70 148 / 20%);
    }
  }


  &.light-business {
    background: #cddaf4;
    border: 2px solid #cddaf4;
    border-radius: 30px;
    color: #163065;

    width: 180px;
 
    &:hover {
      background: #bcc6dc;
      border: 2px solid #bcc6dc;
    }
    &:active {
      transform: scale(0.9);
      background: #c7d6f3;
      box-shadow: 0 2px 25px rgb(32 70 148 / 20%);
    }
  }

  &.light-business-home {
    background: #cddaf4;
    border: 2px solid #cddaf4;
    border-radius: 30px;
    color: #163065;

    width: 145px;
 
    &:hover {
      background: #bcc6dc;
      border: 2px solid #bcc6dc;
    }
    &:active {
      transform: scale(0.9);
      background: #c7d6f3;
      box-shadow: 0 2px 25px rgb(32 70 148 / 20%);
    }
  }
  &.light-register {
    background: #cddaf4;
    border: 2px solid #cddaf4;
    border-radius: 30px;
    color: #163065;
    text-decoration: none;
    width: 266px;
    &:hover {
      background: #bcc6dc;
      border: 2px solid #bcc6dc;
    }
    &:active {
      transform: scale(0.9);
      background: #c7d6f3;
      box-shadow: 0 2px 25px rgb(32 70 148 / 20%);
    }
  }
  &:focus {
    outline: 0;
  }

  &:before,
  &:after {
    position: absolute;
    content: "";
    display: block;
    width: 140%;
    height: 100%;
    left: -10%;
    z-index: -1000;
    transition: all ease-in-out 0.5s;
    background-repeat: no-repeat;
  }

  &:before {
    display: none;
    top: -75%;
    background-image: radial-gradient(circle, $button-bg 10%, transparent 10%),
      radial-gradient(circle, transparent 10%, $button-bg 10%, transparent 30%),
      radial-gradient(circle, $button-bg 10%, transparent 10%),
      radial-gradient(circle, $button-bg 10%, transparent 10%),
      radial-gradient(circle, transparent 10%, $button-bg 15%, transparent 10%),
      radial-gradient(circle, $button-bg 10%, transparent 10%),
      radial-gradient(circle, $button-bg 10%, transparent 10%),
      radial-gradient(circle, $button-bg 10%, transparent 10%),
      radial-gradient(circle, $button-bg 10%, transparent 10%);
    background-size: 10% 10%, 10% 10%, 15% 15%, 10% 10%, 18% 18%, 10% 10%,
      15% 15%, 10% 10%, 18% 18%;
    //background-position: 0% 80%, -5% 10%, 10% 40%, 10% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 10%, 85% 30%;
  }

  &:after {
    display: none;
    bottom: -75%;
    background-image: radial-gradient(circle, $button-bg 10%, transparent 10%),
      radial-gradient(circle, $button-bg 10%, transparent 10%),
      radial-gradient(circle, transparent 10%, $button-bg 15%, transparent 10%),
      radial-gradient(circle, $button-bg 10%, transparent 10%),
      radial-gradient(circle, $button-bg 10%, transparent 10%),
      radial-gradient(circle, $button-bg 10%, transparent 10%),
      radial-gradient(circle, $button-bg 10%, transparent 10%);
    background-size: 15% 15%, 10% 10%, 18% 18%, 10% 10%, 15% 15%, 10% 10%,
      10% 10%;
    //background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
  }

  &:active {
    transform: scale(0.9);
    background-color: darken($button-bg, 5%);
    box-shadow: 0 2px 25px rgb(32 70 148 / 20%);
  }

  &.animate {
    &:before {
      display: block;
      animation: topBubbles ease-in-out 0.75s forwards;
    }
    &:after {
      display: block;
      animation: bottomBubbles ease-in-out 0.75s forwards;
    }
  }

}

@keyframes topBubbles {
  0% {
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%,
      40% 90%, 55% 90%, 70% 90%;
  }
  50% {
    background-position: 0% 80%, 0% 10%, 10% 40%, 10% 0%, 30% 30%, 22% 50%,
      50% 50%, 65% 10%, 90% 30%;
  }
  100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 10% -10%, 30% 10%, 22% 40%,
      50% 40%, 65% 10%, 90% 10%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}

@keyframes bottomBubbles {
  0% {
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%,
      70% -10%, 70% 0%;
  }
  50% {
    background-position: 0% 80%, 10% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%,
      105% 0%;
  }
  100% {
    background-position: 0% 90%, 10% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%,
      110% 10%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}



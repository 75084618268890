.fooTemp {
  position: relative;
  z-index: 10;
  height: 700px;
  @media only screen and (max-width: 768px) {
    z-index: 9;
    height: 869px;

    .HomeForm {
      height: 869px;
      background-color: #0C1A37;
    }
  }



  .fooTemp__container {
    background-color: #0C1A37;
    /* min-height: 700px; */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 30px 100px 0 50px;

    .sectionYellow {
      width: 80%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10%;
      @media only screen and (max-width: 768px) {

        width: 100%;
        height: 869px;
        padding-bottom: 10%;
        margin-right: 7%;
      }

    }


    @media only screen and (max-width: 992px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100vw;
      padding: 0;
      
    }
    @media only screen and (max-width: 768px) {
     
      margin-top: 100px;
     }
    .images {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100vh;
    }
    .foo__content {
      @media only screen and (max-width: 992px) {
       display: flex;
       justify-content: center;
       align-items: center;

      }
      .title_1 {
        font-family: "Poppins-semiBold-700";
        font-style: normal;
        font-weight: bold;
        font-size: 42px;
        line-height: 150%;
        text-align: start;
        color: #ffc629;
        @media only screen and (max-width: 992px) {
          width: 80%;
          text-align: center;
          font-size: 2.1rem;
        }
      }
      .title_2 {
        font-family: "Poppins-semiBold-700";
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 150%;
        text-align: start;
        color: #ffc629;
        @media only screen and (max-width: 992px) {
          width: 80%;
          text-align: center;
          font-size: 1.5rem;

        }
      }
    }

    .goal_Global2 {
      display: flex;
      flex-direction: "row";
      flex-wrap: wrap;
      gap: 12px 24px;
      @media only screen and (max-width: 992px) {
       padding-bottom:100px;
      }
      .goal__item {
        display: flex;
        text-align: left;
        width: 45%;
        p {
          text-align: left;
        }
        .goal__item_icon {
          margin-bottom: 0px;
          margin-right: 17px;
          @media only screen and (max-width: 768px) {
            margin-left: 20px;
          } 
        }
        .goal__item_text {
          text-align: left;
        }
        @media only screen and (max-width: 992px) {
          justify-content: center;
          align-items: center;
          img{
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    .goal_Global {
      display: grid;
      .goal_ {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        margin-bottom: 15px;

        .goal__item {
          display: flex;
          flex-direction: "row";
          text-align: left;
          margin-top: 30px;
          margin-bottom: 30px;
          p {
            width: 150px;
            text-align: left;
          }

          .goal__item_icon {
            margin-bottom: 0px;
            margin-right: 5px;
        

          }
          .goal__item_text {
            text-align: left;
          }
        }
      }
    }

    .subtitle {
      align-self: center;
      max-width: 790px;
      display: flex;
      flex-direction: column;
      min-height: 102px;
      margin-top: 24px;
      font-style: normal;
      font-weight: normal;
      text-align: center;

      h2 {
        font-family: "Poppins-Light";
        font-size: 18px;
        line-height: 27px;
        color: #fefefe;
      }
      h3 {
        font-family: "Poppins-semiBold";
        font-size: 24px;
      }
    }

    .plataformas {
      margin-top: 64px;
      min-height: 149px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      // img{
      //     // margin: 0px 10px;
      // }
    }
    h4 {
      margin-top: 20px;
      font-family: "Poppins-semiBold";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 150%;
      /* identical to box height, or 36px */
      text-align: center;
      /* text2 */

      color: #4a4c4f;
    }

    .pasarelas {
      // border: 1px solid red;
      flex-wrap: wrap;
      display: flex;
      justify-content: space-evenly;
    }
  }
}

@media screen and (min-width: 353px) and (max-width: 745px) {
  .main-footer2  {
    background-color:#394454 !important;
    .containera  {
      .cont-barra {
        .titles {
          margin-top: 5%;
          .lines {
            width: 100%;
            font-size: 12.5px;
            margin-top: 2%;
            .li {
              height: 20px;
            }
            .li-two {
              height: 20px;

            }
          }
          .lines-two {
            width: 100%;
            font-size: 12.5px;
            margin-top: -3%;
            .li {
              height: 20px;
            }
            .li-two {
              height: 20px;
              margin-top: 20px;
            }
          }
        }

        .titles-legal {
          .lines {
            width: 100%;
            font-size: 12.5px;
            margin-top: -3%;
            .li {
              height: 20px;
            }
            .li-two {
              height: 20px;

            }
          }
          .lines-two {
            width: 100%;
            font-size: 12.5px;
            margin-top: -3%;
            .li {
              height: 20px;
            }
            .li-two {
              height: 20px;
              margin-top: 20px;
            }
          }
        }
        .titles-contacto {
          .lines {
            width: 100%;
            font-size: 12.5px;
            margin-top: -3%;
            .li {
              height: 20px;
            }
            .li-two {
              height: 20px;

            }
          }
          .lines-two {
            width: 100%;
            font-size: 12.5px;
            margin-top: -3%;
            .li {
              height: 20px;
            }
            .li-two {
              height: 20px;
              margin-top: 20px;
            }
          }
        }
        .titles-faq {
          .lines {
            width: 100%;
            font-size: 12.5px;
            margin-top: -3%;
            .li {
              height: 20px;
            }
            .li-two {
              height: 20px;

            }
          }
          .lines-two {
            width: 100%;
            font-size: 12.5px;
            margin-top: -3%;
            .li {
              height: 20px;
            }
            .li-two {
              height: 20px;
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
}
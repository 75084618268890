//Desktop y móvil

.form-encuesta {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #e9ecf3;

  .text-encuesta {
    display: flex;
    flex-direction: column;
    width: 545px;

    margin-bottom: 2%;
    @media only screen and (max-width: 768px) {
      width: 100%;
      max-width: 100%;
      padding: 20px;
    }
    .title {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 124%;
      color: #303133;
      @media only screen and (max-width: 768px) {
        padding-top: 10%;
      }
    }
    .subtitle {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #6c7787;
      margin-top: 2%;
    }
  }
  .div-input {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .input-encuesta {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #6c7787;
    &.error-encuesta {
      border: 2px solid red !important;
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
      max-width: 90%;
      margin-left: 1%;
    }
  }
  .error-encuesta-msg {
    margin-left: 50%;
    width: 100%;
    margin-top: -1.5%;
    margin-bottom: 3%;
    @media only screen and (max-width: 768px) {
      margin-left: -65%;
      margin-top: -5%;
    }
  }

  .label-input {
    margin-left: -6%;
    margin-bottom: 2%;
    width: 100%;
    min-width: 40%;
    max-width: 30%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #6c7787;

    @media only screen and (max-width: 768px) {
      width: 100%;
      max-width: 90%;
      padding: 20px;
    }
  }
  .select-encuesta {
    border: none;
    align-items: center;
    width: 545px;
    height: 60px;
    border-radius: 30px;
    background-color: #fff;
    justify-content: center;
    padding: 0px 32px;
    margin-bottom: 20px;
    z-index: 5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg  fill='black' height='40' viewBox='0 0 24 24' width='40' xmlns='http://www.w3.org/2000/svg'><path d='M0  10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 9px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #6c7787;

    &::placeholder {
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      display: flex;
      align-items: center;
      color: #c2c2c2;
    }
    &:focus-visible {
      border: 2px solid #2684ff;
      outline: none;
      box-shadow: 10px #2684ff;
    }

    @media only screen and (max-width: 768px) {
      width: 100%;
      max-width: 90%;
      padding: 15px;
    }
    &.errorselect{
      border: 2px solid red !important;

    }
    &.errorselect-msg{
      border: 2px solid red !important;
      margin-left: 20%;
    }
  }
  .boton-encuesta {
    width: 545px;
    margin-top: 3%;
    @media only screen and (max-width: 768px) {
      width: 100%;
      max-width: 90%;
      margin-left: 1%;
      margin-top: 3%;
    }
  }

}

.react-select {
  width: 545px;
  max-width: 545px;
  margin-top: 20px;
  margin-bottom: 30px;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  @media only screen and (max-width: 768px) {
    width: 545px;
    max-width: 236px;
    margin-top: 20px;
    margin-bottom: 30px;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-left: -16%;
  }

  &.error {

    .css-13cymwt-control {
      border: 2px solid red !important;

    }
  }

  .css-13cymwt-control {
    border-radius: 54px;
    border: none;
    padding-left: 5%;
    height: 60px;

    .css-qbdosj-Input {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 30px;
    }
    @media only screen and (max-width: 768px) {
      margin-left: 20%;
      width: 130%;
      padding-left: 8%;
      .css-qbdosj-Input {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 30px;
      }
    }
  }

  .css-t3ipsp-control {
    border-radius: 54px;
    height: 60px;
    padding-left: 5%;
    
    .css-qbdosj-Input {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 30px;
    
    }
    @media only screen and (max-width: 768px) {
      margin-left: 20%;
      width: 130%;
      padding-left: 8%;

      .css-qbdosj-Input {
        display: flex;
        align-items: center;
        justify-content: center;
  
        height: 30px;
      
      }
    }
  }

  // .css-b62m3t-container {
  //   max-width: 545px;
  // }

  .msg-error {
    margin-left: 2%;
    margin-top: 4%;
    @media only screen and (max-width: 768px) {
      margin-left: -10%;
    }

  }
}
.select-input {
  background: transparent;

  #react-select-3-listbox {
    @media only screen and (max-width: 768px) {
      margin-left: 20%;
      width: 130%;
    }
  }
}
.css-6j8wv5-Input {
  margin: auto !important;
}
.css-1s2u09g-control {
  padding: 1rem;
  min-width: 320px;
  max-width: 545px;
  width: 545px;
  height: 60px !important;
  padding: 0px 21px;
  margin-bottom: 10px;
  border-radius: 30px !important;
  background: white !important;
  border: none !important;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  @media only screen and (max-width: 768px) {
    width: 60%;
    max-width: 20%;
    margin-top: 20px;
    margin-left: 20%;
  }
  &::placeholder {
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}
.css-1okebmr-indicatorSeparator {
  display: none;
}
.css-tlfecz-indicatorContainer {
  color: #000 !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px;
  -webkit-transition: color 150ms;
  transition: color 150ms;
  box-sizing: border-box;
}

.css-1pahdxg-control {
  padding: 1rem;
  width: 545px;

  height: 60px !important;
  padding: 0px 21px;
  border-radius: 30px !important;
  background: white !important;
  margin-bottom: 10px;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  @media only screen and (max-width: 768px) {
    width: 545px;
    max-width: 320px;
    margin-left: 20%;
  }
}
.css-1pahdxg-control:hover {
  padding: 1rem;
  width: 545px;
  height: 60px !important;
  padding: 0px 21px;
  border-radius: 30px !important;
  background: white !important;
  border: none !important;
  box-shadow: none !important;
}

.css-1okebmr-indicatorSeparator {
  display: none;
}
.css-1pahdxg-indicatorContainer {
  color: #000 !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px;
  -webkit-transition: color 150ms;
  transition: color 150ms;
  box-sizing: border-box;
}

.css-6j8wv5-Input {
  margin-top: -5% !important;

  padding-bottom: 2px;
  padding-top: 2px;
  visibility: visible;
  color: #9199a5 !important;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: inline-grid;
  grid-area: 1/1/2/3;
  grid-template-columns: 0 min-content;
  box-sizing: border-box;
}
.css-14el2xx-placeholder {
  color: hsl(216, 12%, 61%, 0.6) !important;
  grid-area: 1/1/2/3;
  margin-left: 2px;
  margin-right: 2px;
  box-sizing: border-box;
}

.css-1gtu0rj-indicatorContainer {
  border-color: red !important;
}

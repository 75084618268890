.bg-gray {
  background-color: #fafafa;
}
.dark-blue {
  background-color: #121a2c;
  color: #fff;
      z-index: 2;
}
.blue {
  background-color: #fafafa;
  color: #fff;
}

.hiw-left,
.hiw-right {
  display: flex;
  align-items: center;
  height: 588px;
}

.hiw-right {
  display: flex;
  align-items: flex-end;

  .image {
    width: 100%;
    object-fit: fill;
  }
}

// .Wrapper-cabecera {
//   height: 588px;
//   width: 100%;
//   padding: 0 106px;
//   background-color: #fafafa;

//   .cabecera {
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     justify-content: space-between;
//   }
//   .img-cabecera {
//     width: 50%;
//   }
// }

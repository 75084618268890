@import "theme/variables.scss";

.container {
  position: relative;
  width: 155px;
  height: 50px;
  outline: 0;
  border: 0;
  overflow: hidden;
  cursor: pointer;
  font-weight: bold;
  text-transform: capitalize;
  border-radius: 6px;
  font-family: Montserrat-semiBold;
  font-size: 16px;
  box-sizing: border-box;
  overflow: hidden;
  text-decoration: none;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-right: 2em;

  &:before {
    content: '';
    position: absolute;
    left: 60px;
    top: 50%;
    transform: translateY(-50%) scale(0.6);
    width: 20px;
    height: 20px;
    background-color: $secondary-color;
    border-radius: 50%;
    transition-property: left, transform;
    transition-delay: 0s, 0s;
    transition-duration: 0.5s, 0.2s;
    z-index: -1;
    opacity: 0;
  }

  &::after {
    content: '';
    position: absolute;
    right: 60px;
    top: 50%;
    transform: translateY(-50%) scale(0.6);
    width: 20px;
    height: 20px;
    background-color: $secondary-color;
    border-radius: 50%;
    transition-property: right, transform;
    transition-delay: 0s, 0s;
    transition-duration: 0.5s, 0.2s;
    z-index: -1;
    opacity: 0;
  }

  &:hover {
    z-index: 1;
    &::before {
      left: 10px;
      transform: translateY(-50%) scale(20);
      transition-delay: 0s, 0.8s;
      opacity: 1;
    }

    &::after {
      right: 10px;
      transform: translateY(-50%) scale(20);
      transition-delay: 0s, 0.8s;
      opacity: 1;
    }
  }

  &.primary {
    background-color: $primary-color;
    color: $text-white;
  }

  &.secondary {
    background-color: $secondary-color;
    color: $text-white;

    &::before {
      background-color: $primary-color;
    }

    &::after {
      background-color: $primary-color;
    }
  }
}

.parallax-option {
  background-color: transparent;

  .parallax-child {
    &.fixed {
      position: fixed;
      top: 35px;
      padding: 0 100px;
      left: 0px;
      width: 100%;
    }
  }
}

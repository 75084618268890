.WhatCanYouDo {
  background-color: #0C1A37;
  height: 100%;
  min-height: 745px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px 100px 0 100px;
justify-content: center;
  align-content: center;
  flex-wrap: nowrap;
  @media only screen and (max-width: 768px) {

    justify-content: left;
    text-align: left;
    padding: 24px;
    z-index: 999;

  }
  .title {
    font-family: "Poppins-semiBold-700";
    font-weight: 700;
    font-size: 48px;
    line-height: 124%;
    text-align: center;
    color: #ffc629;
    @media only screen and (max-width: 768px) {
      width: 314.39px;
      text-align: center;
      justify-content: center;
      align-items: center;
      margin-left: 7%;
    }
  }

  .subtitle {
    align-self: center;
    max-width: 790px;
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    font-style: normal;
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    h2 {
      font-family: "Poppins";
      font-size: 18px;
      line-height: 27px;

      color: #fff;
      margin-left: 7%;

    }
    h3 {
      font-family: "Poppins-semiBold";
      font-size: 24px;
    }
    @media only screen and (max-width: 768px) {

      justify-content: left;
      text-align: left;
      padding: 24px;
    }
  }

  .plataformas {
    margin-top: 64px;
    min-height: 149px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    // img{
    //     // margin: 0px 10px;
    // }
  }
  h4 {
    margin-top: 20px;
    font-family: "Poppins-semiBold";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    /* identical to box height, or 36px */
    text-align: center;
    /* text2 */

    color: #4a4c4f;
  }

  .pasarelas {
    // border: 1px solid red;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-evenly;

    &.last {
      margin-bottom: 40px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .WhatCanYouDo .title {
    font-family: "Poppins-bold";
    font-style: normal;
    font-weight: bold;
    font-size: 2.1rem;
    line-height: 114%;
    text-align: center;
    color: #ffc629;
  }
  .WhatCanYouDo {
    height: auto;
  }
}
@media (max-width: 906px) {
  .WhatCanYouDo {
    padding: 30px 0px 0 0px;
  }
}

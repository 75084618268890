.content-legal {
  margin-top: 20vh;
  margin-bottom: 12vh;
  padding: 0 7vw;
  font-family: Poppins;
  font-size: 16px;

  .content-legal22 {
    width: 100%;
    text-align: justify;
    display: grid;
    position: inherit;
  }
  .h1 {
    text-align: left;
  }
  .h4 {
    text-align: left;
  }
}
@media (max-width: 992px) {
  .content-legal {
    text-align: left;
    position: relative;
    width: 100%;
    margin-left: 1px;
    font-family: Poppins;
    font-size: 16px;
    padding: 0;
    .text-inimage, .text-inimage2{
      padding: 0 7vw;
    }
    .text-inimage{
      font-size: 1.6rem;
    }
    .text-inimage2{
      width: 150%;
      font-size: 2rem;
     
    }
    .content-legal22 {
      display: grid;
      margin-left: 0px;
      text-align: justify;
      padding: 0 7vw;
      position: relative;
      width: 100%;
      margin-left: 1px;
      font-family: Poppins;
      font-size: 16px;
    }
    .content-legal23 {
      display: grid;
      text-align: justify;
      padding: 0 7vw;
      position: relative;
      width: 100%;
      margin-left: 1px;
      font-family: Poppins;
      font-size: 16px;
    }
  }

  .left-pointer {
    margin-left: -40px;
  }
}

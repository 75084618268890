.whatsapp-wrapper {
  z-index: 99;
  position: fixed;
  bottom: 10%;
  right: 2%;

  display: flex;

  margin-top: 10px;
  justify-items: center;

.link-whatsapp{
    display: flex;
    flex-direction: row-reverse;
    text-decoration: none;
    color: black;
    .icon-whatsapp {
        width: 60px;
        height: 60px;
        margin-top: 15px;
      }
      .text-whatsapp {
        background-color: white;
        width: fit-content;
        height: 50%;
        font-size: 12px;
        margin-top: 30px;
        padding: 10px;
        margin-right: 15px;
        border-radius: 8px;
        display: flex;
        
    
        align-items: center;
      }
}

}

@media only screen and (max-width: 768px) {
    .whatsapp-wrapper {
        z-index: 10;
        position: fixed;
        bottom: 18%;
        right: 2%;
      
        display: flex;
      
        margin-top: 10px;
        justify-items: center;
      
      .link-whatsapp{
          display: flex;
          flex-direction: row-reverse;
          text-decoration: none;
          color: black;
          .icon-whatsapp {
              width: 60px;
              height: 60px;
              margin-top: 15px;
            }
            .text-whatsapp {
              background-color: white;
              width: fit-content;
              height: 50%;
              font-size: 12px;
              margin-top: 30px;
              padding: 10px;
              margin-right: 15px;
              border-radius: 8px;
              display: none;
              
          
              align-items: center;
            }
      }
      
      }
}



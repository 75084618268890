/* @import "../../styles/colors.scss";
@import "../../styles/index.scss"; */
.modal {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 100;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.685);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: none;
  &.active {
    display: flex;
  }
  .closebutton {
    width: 32px;
    height: 32px;
    z-index: 2;
    top: 8px;
    right: 8px;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    cursor: pointer;
    border-radius: 50%;
    position: absolute;
  }

  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: fit-content;
    border-radius: 10px;

    @media only screen and (max-width: 768px) {
      width: 80%;
      margin-left: -5%;

    }
    .modal-body {
      /*    background-color: $blue_gray_300; */
      background-image: url("../../assets/images/unavailableModal.svg");
      background-position: left;
      background-repeat: no-repeat;
      display: flex;
      justify-content: flex-end;
      width: 722px;
      height: 499px;
      border-radius: 10px;
      @media only screen and (max-width: 992px) {
        width: 100%;
        background-size: 0px;
      }
    }
  }
}

.modal-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 150%;
  color: #1e428a;
  @media only screen and (max-width: 992px) {
   text-align: center;
  }
}
.modal-subTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  color: #475569;
}
.modal-paragraph {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #6c7787;
}

.modal-unavailable {
  margin: 0 31px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  gap: 6px;
  @media only screen and (max-width: 992px) {
    width: 100%;
  }
}
@import "../../theme/variables.scss";
.who-container {
  @media only screen and (max-width: 992px) {
    display: none;
  }
}
.font.h12 {
  font-size: 50px;
  font-family: "Poppins";
  font-weight: 700;
  line-height: 3.5rem;
  margin-bottom: 2px;
  margin-right: 20px;
  width: auto;
  max-width: 900px;
}

.div-licencia {
  background-color: white;
  display: flex;
  flex-direction: row;
  min-height: 700px;
  // position: absolute;
  z-index: 999;

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
    padding-bottom: 5%;
  }
  .div-text-licencia {
    width: 50%;
    padding-left: 106px;
    padding-top: 183px;
    .link {
      text-decoration: none;
      color: #1e428a;
      font-family: "Poppins-bold";
      font-weight: 400;
      font-size: 18px;
    }
    @media only screen and (max-width: 768px) {
      width: 90%;
      padding-left: 30px;
      padding-top: 20px;
      z-index: 1;
    }
    .title {
      font-family: "Poppins-bold";
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 124%;
      color: #1e428a;

      @media only screen and (max-width: 768px) {
       font-size: 2.1rem ;
      }
    }

    .text-licencia {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      /* or 27px */

      /* text/text_80 */

      color: #2b333f;
    
    }
  }
  .div-img-licencia {
    width: 50%;
    z-index: 9;
    background: white;

    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
}

.div-disclaimer {
  background-color: #0c1a37;
  display: flex;
  flex-direction: row;
  min-height: 700px;
  padding-bottom: 5%;
  


  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
  .div-text-disclaimer {
    width: 50%;
    padding-left: 106px;
    padding-top: 160px;
    .link {
      text-decoration: none;
      color: white;
      font-family: "Poppins-bold";
      font-weight: 400;
      font-size: 18px;

      margin-left: 5%;
    

    }
    @media only screen and (max-width: 768px) {
      width: 90%;
      padding-left: 30px;
      padding-top: 20px;
    }
    .title-disclaimer {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 124%;
      color: #ffc627;
      @media only screen and (max-width: 768px) {
        font-size: 2.1rem ;
       }
    }
    .text-disclaimer {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      color: #ffffff;
    }
    .item-disclaimer {
      color: white;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      gap: 12px;
      color: #ffffff;
      display: flex;
      flex-direction: row;
      margin-bottom: 5%;
    }
  }
  .div-img-disclaimer {
    width: 50%;
    padding-right: 106px;
    margin-right: 106px;
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
}

.div-terms {
  background-color: white;
  display: flex;
  flex-direction: row;
  min-height: 700px;
  padding: 123px 116px;
  gap: 15%;
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    padding-top: 20px;
  }

  .div-text-terms {
    .title {
      width: 150%;
      display: flex;

      @media only screen and (max-width: 768px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        font-size: 2.1rem ;
      }
     

      .title-term-one {
        font-family: "Poppins-bold";
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 124%;
        color: #2b333f;
        @media only screen and (max-width: 768px) {
          font-size: 2.1rem;
  
        }
      }
      .title-term-two {
        font-family: "Poppins-bold";
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 124%;
        color: #015094;
        margin-left: 2%;
        @media only screen and (max-width: 768px) {
          font-size: 2.1rem;
          width: 160%;
        }
      }
    }
    .accordion {

      gap: 16px;
      .accordion-terms {
        @media only screen and (max-width: 768px) {
          margin-left: -10%;
          width: 150%;
        }
        .header {
          .title {
            @media only screen and (max-width: 768px) {
              font-size: 18px;
            }
            
          }
        }
      }
    }
  }

  .div-terms-two {
    margin-top: 5%;
    z-index: 5;
    .terms-two {
      
        .accordion-terms {
          @media only screen and (max-width: 768px) {
            margin-left: -5%;
            width: 143%;
          }
          .header {
            .title {
              @media only screen and (max-width: 768px) {
                font-size: 18px;
              }
              
            
          }
        }
      }
    }
  }

  .collapse__content.collapsed {
    display: none;
  }
  .collapsed__content.expanded {
    display: block;
  }
}
.contunido {
  display: grid;
  grid-template-columns: 0.8fr 300px;
  justify-content: left;
  text-align: left;
  width: 450px;
  margin-right: 0px;
}
.contunido2 {
  display: grid;
  grid-template-rows: 1fr 1fr;
  justify-content: left;
  text-align: left;
  width: 750px;
  margin-right: 2px;
}

.contunido3 {
  display: grid;
  grid-template-columns: 0.7fr 300px;
  justify-content: left;
  text-align: left;
  width: 420px;
  margin-right: 1px;
}

.wrapper-who {
  position: relative;
  width: 100%;
  margin-top: 74px;
  background-color: #0c1a37;
  .section-home {
    height: 100vh;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 100px;

    .logo-title {
      width: 444px;
      margin-bottom: 15px;
    }
    .button {
      height: 71px;
      width: 325px;
      background-color: $primary-color;
      color: white;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-self: flex-start;
      border: none;

      border-radius: 60px;
      margin-top: 24px;
      font-family: "Poppins";
      &:hover {
        cursor: pointer;
      }
    }
    .item {
      margin-top: 10px;
      margin-left: 30px;

      span {
        display: flex;
        align-items: center;
      }
      .ic-money {
        margin-right: 8px;
        svg {
          stroke: #819999;
        }
      }
    }
  }
  .images {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 50%;
    display: flex;
    align-items: flex-end;

    &.end-animation {
      position: absolute;
      top: inherit;
      bottom: 0;
    }

    .image-home {
      position: absolute;
      object-fit: contain;
      object-position: bottom;
      height: 100%;
      transition: opacity 1s;

      &.invert {
        transform: rotateY(180deg);
      }
      &.show {
        opacity: 1;
      }
      &.hidden {
        opacity: 0;
      }
    }
  }
}
.last-section {
  position: relative;
  height: 100vh;
  background-color: #121a2c;
  .content {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 100px;

    .goal {
      display: flex;

      .sub-icon {
        height: 24px;
        width: 24px;
        color: #475571;
        margin-right: 15px;
        margin-bottom: 12px;
      }
      p {
        margin-right: 30px;
        font-size: 15px;
        color: #fff;
      }
    }
  }
  .images {
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    width: 50%;
    display: flex;
    align-items: flex-end;

    .image-home {
      position: absolute;
      object-fit: contain;
      object-position: bottom;
      width: 88%;
      transition: opacity 1s;
    }
  }
}

// responsive

@media (max-width: 700px) {
  .last-section {
    height: auto;
    padding-top: 100px;
    margin-top: 40px;
    .content {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-left: 0px;

      .goal {
        flex-direction: row;
        align-items: center;
        margin-bottom: 15px;

        .sub-icon {
          margin-bottom: 0px;
        }
      }
    }
    .image-home-rs {
      object-fit: contain;
      width: 100%;
    }
    .sectionYellow {
      width: 10px;
  

      @media only screen and (max-width: 768px) {
        z-index: 1;
      }
    }
  }
}

.video-home {
  width: 100%;
  margin-bottom: 2.09rem;
}

body {
  overflow-x: hidden !important;
}
@media (max-width: 1200px) {
  .contunido {
    display: grid;
    grid-template-columns: 0.65fr 300px;
    justify-content: left;
    text-align: left;
    width: 100px;
    margin-right: 2px;
  }
  .contunido2 {
    display: grid;
    grid-template-rows: 1fr 1fr;
    justify-content: left;
    text-align: left;
    width: 320px;
    margin-right: 2px;
  }
  .contunido3 {
    display: grid;
    grid-template-columns: 0.7fr 300px;
    justify-content: left;
    text-align: left;
    width: 100px;
    margin-right: 1px;
  }
  .font.h12 {
    font-size: 35px;
    font-family: "Poppins";
    font-weight: 700;
    line-height: 3.5rem;
    margin-bottom: 2px;
    width: auto;
    max-width: 240px;
  }
}

@import "../../theme/variables.scss";

.steps-content {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .row {
    &.sticker {
      position: fixed;
      top: 0;
    }
  }
  .step-title {
    margin-bottom: 30px;
    color: $secondary-color;
    font-size: 30px;
  }
  .container-text {
    min-height: 380px;
    .option {
      align-self: flex-start;
      border-left: 1px solid #fff;
      display: flex;
      flex-direction: column;
      padding-bottom: 1em;
      transition-duration: 0.5s;
      cursor: pointer;

      .name {
        color: $text-white-dark;
        opacity: 0.3;
        font-family: Montserrat-Bold;
        font-size: 1.87rem;
        padding-left: 1em;
        padding-bottom: 0.6em;
        transition-duration: 0.4s;
      }

      .comment {
        position: relative;
        font-family: Montserrat-Light;
        font-size: 15px;
        line-height: 24px;
        padding-left: 2em;
        max-width: 360px;
        color: #5e5e5e; //$text-gray-dark;

        &.active {
         
          animation-delay: 2s;
          animation: commentAnimation 1.5s 1;
          animation-fill-mode: forwards;
        }
      }

      &.active {
        border-left: 1px solid $secondary-color;
        padding-bottom: 2em;
        .name {
          padding: 0.5em;
          color: $secondary-color;
          font-size: 2.87rem;
          line-height: 50px;
          opacity: 1;
          width: 100%;
        }
      }
    }
  }
}
.slider-content {
  display: flex;
  justify-content: center;

  &.center {
    align-items: center;
  }
  &.top {
    align-items: flex-start;
  }
  &.bottom {
    align-items: flex-end;
  }

  .img-content {
    .img {
      display: none;
      &.active {
        display: inline-block;
        height: 96%;

        animation-delay: 2s;
        animation: imgAnimation 1s 1;
        animation-fill-mode: forwards;
      }
    }
  }
}
.steps-icons {
  position: absolute;
  top: 207px;
  right: 0px;
}
@keyframes commentAnimation {
  0% {
    color: $text-gray-dark;
    left: 90px;
  }
  100% {
    left: 0px;
    color: #fff;
  }
}
@keyframes imgAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

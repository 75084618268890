.section {
  width: 100%;
  padding: 0px 1.25rem;
  position: relative;

  &.full {
    padding: 0px;
  }
}

@media only screen and (max-width: 768px) {
 
  //tablet >
  .section {
    padding: 0px 2vh;
  }
  
}



@import "../../theme/variables.scss";

html {
  font-size: 16px; //define REM desktop
}
.font {
  margin: 0;
  &.h3 {
    font-family: "Poppins-Bold";
    font-weight: 700;
    font-size: 48px;
    line-height: 124%;
    margin-bottom: 24px;
  }
  &.h1 {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 60px;
    line-height: 124%;
    margin: 2px;
    margin-right: 5px;
  }
  &.h2 {
    font-family: "Poppins";
    font-weight: 700;
    font-size: 26px;
    line-height: 146%;
    margin: 14px 0px;
  }
  &.text {
    font-family: "Poppins";
    font-weight: 400;
    font-size: 18px;
    line-height: 146%;
    margin: 6px 0px;
    text-align: justify;
  }

  &.small {
    font-size: 0.875rem;
    font-family: "Poppins";
    font-weight: 400;
    line-height: 1.5rem;
  }
  &.error {
    color: red;
    align-self: flex-start;
    margin-left: 5% ;
    @media only screen and (max-width: 768px) {
      margin-left: 42%;

    }

  }

  &.bold {
    font-weight: bold;
  }
  &.primary {
    color: $primary-color;
    /*$secondary-color: #ffc629;*/
  }

  &.secondary {
    color: $secondary-color;
    /*$secondary-color: #ffc629;*/
  }
  &.white {
    color: $text-white;
  }
  &.black {
    color: $black-color;
  }
}

@media (max-width: 1200px) {
  //tablet >
  html {
    scroll-behavior: smooth;
    font-size: 16px; //define REM mobile
  }
  .font {
    &.h1 {
      font-size: 2.28rem;
      font-family: "Poppins";
      font-weight: 700;
      line-height: 2.85rem;
      margin-bottom: 2px;
    }
    &.h2 {
      font-size: 1.5rem;
      font-family: "Poppins";
      font-weight: 700;
      line-height: 2.25rem;
      margin-bottom: 24px;
    }

    &.text {
      margin: 5px 0;

      font-size: 1rem;
      font-family: "Poppins";
      font-weight: 400;
      line-height: 1.5rem;
    }
    &.black {
      color: $black-color;
    }
  }
}

@media (max-width: 978px) {
  //tablet >
  html {
    scroll-behavior: smooth;
    font-size: 15px; //define REM mobile
  }
  .font {
    &.h1 {
      font-size: 2.28rem;
      font-family: "Poppins";
      font-weight: 700;
      line-height: 2.85rem;
      margin-bottom: 2px;
    }
    &.h2 {
      font-size: 1.5rem;
      font-family: "Poppins";
      font-weight: 700;
      line-height: 2.25rem;
      margin-bottom: 24px;
    }

    &.bold {
      font-weight: bold;
    }
    &.text {
      margin: 5px 0;

      font-size: 1rem;
      font-family: "Poppins";
      font-weight: 400;
      line-height: 1.5rem;
    }

    &.primary {
      color: $primary-color;
      $secondary-color: #ffc629;
    }

    &.secondary {
      color: $secondary-color;
    }
    &.white {
      color: $text-white;
    }
    &.black {
      color: $black-color;
    }
  }
}

@media (max-width: 916px) {
  //tablet >
  html {
    scroll-behavior: smooth;
    font-size: 14px; //define REM mobile
  }
  .font {
    &.h1 {
      font-size: 2.28rem;
      font-family: "Poppins";
      font-weight: 700;
      line-height: 2.85rem;
      margin-bottom: 2px;
    }
    &.h2 {
      font-size: 1.5rem;
      font-family: "Poppins";
      font-weight: 700;
      line-height: 2.25rem;
      margin-bottom: 24px;
    }

    &.bold {
      font-weight: bold;
    }
    &.text {
      margin: 5px 0;

      font-size: 1rem;
      font-family: "Poppins";
      font-weight: 400;
      line-height: 1.5rem;
    }

    &.primary {
      color: $primary-color;
      $secondary-color: #ffc629;
    }

    &.secondary {
      color: $secondary-color;
    }
    &.white {
      color: $text-white;
    }
    &.black {
      color: $black-color;
    }
    &.center {
      text-align: center;
    }

    &.left {
      text-align: left;
    }
    &.right {
      text-align: right;
    }
  }
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .font.h1 {
    font-size: 35px;
    font-family: "Poppins";
    font-weight: 700;
    line-height: 3.5rem;
    margin-bottom: 2px;
  }
  .font.h2 {
    font-size: 21px;
    font-family: "Poppins";
    font-weight: 700;
    line-height: 2.25rem;
    margin-bottom: 24px;
  }
  .font.white {
    color: #ffffff;
  }
}

.container-buttonV {
  display: flex;
  align-items: center;
  padding-left: 5px;
  width: 18.25rem;
  height: 63px;
  border-radius: 16px;
  cursor: pointer;
  text-decoration: none;

  &.primary {
    background-color: #2653b0;
  }
  &.gray {
    background-color: #e8e8e8;
  }
  &.light-gray {
    background-color: #f4f4f6;
    color: #1e428a;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
  }

  &.dark-blue {
    background-color: #122853;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
  }

  .display {
    border-radius: 16px;
    margin-right: 15px;
  }
  .ic-play {
    display: flex;
    align-items: center;
    margin-left: 15px;

    &.gray {
      svg {
        path {
          fill: #303133;
        }
      }
    }
    &.light-gray {
      svg {
        path {
          fill: #1e428a;
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .container-buttonV {
    width: 100%;
    justify-content: space-between;
    padding: 0px 3vh 0px 2vh;
  }
  .video-link .font.white {
    color: #ffffff;
    padding: 0vh 0vh 0vh 0vh;
  }

  .container-buttonV .ic-play {
    display: flex;
    align-items: center;
    margin-left: 25px;
    background-color: #fec52e;
    color: #121a2c;
    padding: 1vh;
    border-radius: 50%;
  }
}

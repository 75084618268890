@import "../../theme/variables.scss";
.people-container {
  @media only screen and (max-width: 992px) {
    display: none;
  }

  .foo__content {

    .goal__item_icon{
      margin-right: 2%;
    }
    .title_obj {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 150%;
      color: white;
      margin-bottom: 5%;
    }
  }
}
body {
  overflow-x: hidden !important;
}
.bg__white {
  background-color: white;
  z-index: 2;
  .title {
    font-family: "Poppins-semiBold-700";
    font-size: 60px;
    line-height: 150%;
    color: #1c222a;
    span {
      font-family: "Poppins-semiBold-700";
      color: #1e428a;
    }
  }
  .paragraph {
    font-family: "Poppins";
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: #1c222a;
    margin: 0;
  }

  .section2,
  .section3,
  .section4,
  .section5 {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .title-logo {
      padding: 0;
    }
  }
}

.foo__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;


  .paragraph {
    margin: 24px 0;
  }
}

// responsive

@media (max-width: 700px) {
  .last__section_product {
    height: auto;
    padding: 100px 20px;
    background-color: #121a2c;

    .foo__content {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-left: 0px;
    }
    .images {
      position: inline-block;
    }
  }
}

.video-home {
  width: 100%;
  margin-bottom: 2.09rem;
}

@import "../../theme/variables.scss";
.home-container{

  @media only screen and (max-width: 992px) {
    display: none;
  }
}
.wrapper-home {
  position: relative;
  width: 100%;
  margin-top: 74px;
  .section-home {
    height: 100vh;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 100px;

    .button {
      height: 71px;
      width: 325px;
      background-color: $primary-color;
      color: white;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-self: flex-start;
      border: none;

      border-radius: 60px;
      margin-top: 24px;
      font-family: "Poppins";
      &:hover {
        cursor: pointer;
      }
    }
    .item {
      span {
        display: flex;
        b{
          margin-right: 20px;
        }
      }
      .ic-money {
        width: 20px;
        margin-right: 8px; 
      }
    }
  }
  .images {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 40%;
    display: flex;
    align-items: flex-end;

    &.end-animation {
      position: absolute;
      top: inherit;
      bottom: 0;
    }

    .image-home {
      position: absolute;
      object-fit: contain;
      object-position: bottom;
      height: 100%;
      transition: opacity 1s;

      &.show {
        opacity: 1;
      }
      &.hidden {
        opacity: 0;
      }
    }
  }
  .form {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.last-section {
  position: relative;
  height: 100vh;
  background-color: #121a2c;
  .content {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 100px;

    .goal {
      display: flex;

      .sub-icon {
        height: 24px;
        width: 24px;
        color: #475571;
        margin-right: 15px;
        margin-bottom: 12px;
      }
      p {
        margin-right: 30px;
        font-size: 15px;
        color: #fff;
      }
    }
  }
  .images {
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    width: 50%;
    display: flex;
    align-items: flex-end;

    .image-home {
      position: absolute;
      object-fit: contain;
      object-position: bottom;
      width: 88%;
      transition: opacity 1s;
    }
  }
}

// responsive

@media (max-width: 768px) {
  .last-section {
    height: auto;
    padding-top: 100px;
    margin-top: 40px;
    .content {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-left: 0px;

      .goal {
        flex-direction: row;
        align-items: center;
        margin-bottom: 15px;

        .sub-icon {
          margin-bottom: 0px;
        }
      }
    }
    .image-home-rs {
      object-fit: contain;
      width: 100%;
    }
  }
}

.video-home {
  width: 100%;
  margin-bottom: 2.09rem;
}

body{
  overflow-x: hidden !important;
}

.CookieConsent {
  align-items: baseline;
  background: #204694;
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  left: 0px;
  position: fixed;
  width: 100%;
  z-index: 999;
  bottom: 0px;
  font-family: "Poppins" !important;
 
}
.CookieConsent a 
  {
    cursor: none;
    padding-left: 1vh !important;
    color: #ffc629 !important;
    list-style: none !important;
    text-decoration: none !important;
  }
#rcc-confirm-button {
  border-radius: 50px !important;
  font-size: 16px !important;
}

.buttons-home{
  display: flex;
  gap: 24px;
  .text-business {
    text-decoration: none;
    color: #204694;
    font-size: 18px;
  }

}


.Head {
  width: 100%;
  height: 320px;
  background-color: #0c1a37;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 100px;
  padding-right: 80px;
  @media only screen and (max-width: 768px) {
    height: 384px;
    padding-left: 64px;
  padding-right: 64px;
  }
  .title-question {
    font-family: "Poppins-Bold";
    font-weight: 700;
    font-size: 48px;
    line-height: 124%;
    color: #fff;
    @media only screen and (max-width: 768px) {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 150%;
      /* or 48px */
      
      
      color: #FFFFFF;
    }
  }
  .subtitle-question {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 146%;
    color: #fff;

    @media only screen and (max-width: 768px) {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      /* or 21px */
      
      
      color: #FFFFFF;
      
    }
  }
  .title {
    color: #fff;
    line-height: 160px;
    font-size: 56px;
  }
  p {
    color: #fff;
    font-size: 18px;
  }
}
.wall {
  background-color: #fff;
  width: 100%;
  height: 100%;
  margin-bottom: 130px;


  .top {
    position: relative;
    display: flex;
    margin-top: 74px;
    justify-content: center;
    margin-bottom: 80px;
    flex-wrap: wrap;

    ::after {
      position: absolute;
      content: "";
      bottom: 0px;
      left: 0px;
      width: 100%;
      height: 4px;
      background-color: #e5e5e5;
    }

    .item-tab-accordion {
      cursor: pointer;
      position: relative;
      @media only screen and (max-width: 768px) {
        justify-content: center;
        align-items: center;
        text-align: center;
      }
      &.active::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 4px;
        background-color: #ffc629;
        bottom: 0px;
        left: 0;
        border-radius: 5px;
      }

      .option__faq {
        display: flex;
        flex-direction: column;
    
     
      }
      .svg_all {
        flex-direction: column;
        width: 90%;
        margin-top: 12.5%;

        @media only screen and (max-width : 900px) {
          flex-direction: column;
          width: 90%;
          margin-top: 12.5%;
          }

          @media only screen and (max-width:768px) {
        
            align-items: center;
            justify-content: center;
            display: flex;
            margin-left: -5%;
            }
   
      }
      .text-personas {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        width: 238px;
        height: 30px;
        margin-top: 21.5%;
   

        @media only screen and (max-width:768px) {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 36px;
          width: 238px;
          height: 30px;
          margin-left: 20%;
          align-items: center;
          justify-content: center;
          display: flex;
      
          }
 
      }
      .word {
        color: #1a428a;

        fill: #1a428a;

        &.disabled {
          color: #b0b5bf;
          fill: #b0b5bf;
          @media only screen and (max-width:768px){
            align-items: center;
            justify-content: center;
            display: flex;
            margin-left: 17.5%;
          }
        }
      }

      .lastIcon {
        margin-top: 22px;
      }
    }
  }
  .divider {
    display: flex;
    justify-content: center;
    padding: 0 100px;
    hr {
      height: 5px;

      width: 100%;
      margin-bottom: 80px;
      border-radius: 5px;
      border: none;
    }
  }

  .faq-web-wao {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      margin-top: 0;
      margin: 1rem !important;
    }
    .faq-item {
      width: 100%;
      box-sizing: border-box;
      border: 1px solid #f2f2f2;
      border-radius: 8px;
      margin-bottom: 12px;
      padding: 26px;
      font-size: 20px;
      font-weight: 600;
      cursor: pointer;

      .faq-header {
        display: flex;
        justify-content: space-between;
      }

      .title-faq {
        color: #303133;
        cursor: pointer;

        &.active {
          color: #2f80ed;
        }
      }
      img {
        cursor: pointer;
      }
      .content {
        p {
          .blue {
            color: #2f80ed;
          }
          .blue-dark {
            color: #1a428a;
          }
          .red {
            color: red;
          }
          .note {
            font-size: 12px;
          }
          a {
            color: #2f80ed;
            font-weight: 700;
          }
        }
        &.show {
          font-family: Poppins;
          font-size: 16px;
          margin-top: 16px;
          font-weight: 200;
          // line-height: 150%;
          // transition: all 0.5s cubic-bezier(1, 0, 1, 0);
        }
        &.hidden {
          height: 0px;
          overflow: hidden;
        }
      }
    }
  }

  .Footer {
    background-color: #121a2c;
    width: 100%;
    height: 250px;
    display: flex;
    align-items: center;
    color: white;

    .section-footer {
      width: 100%;
      .section-footer-content {
        display: flex;
        padding: 0px 120px;
        justify-content: space-between;

        a {
          color: white;
          text-decoration: none;
        }
        .bold {
          font-weight: 600;
        }

        .link {
          font-size: 14px;
        }
        .title {
          font-size: 14px;
        }

        img {
          width: 30px;
          margin: 5px;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .wall {
    .top {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      ::after {
        position: absolute;
        justify-content: center;
        content: "";
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 4px;
        background-color: #e5e5e5;
      }
      .item-tab-accordion {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;

        &.active::after {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          position: absolute;
          content: "";

          height: 4px;
          background-color: #ffc629;
          bottom: 0px;
          left: 0;
          border-radius: 5px;
          text-align: center;
        }
        .option__faq {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
        .svg_all {
          display: flex;
          flex-direction: column;
          width: 100%;
          
        }
        .word {
          text-align: center;
          justify-content: center;
          fill: #1a428a;
          &.disabled {
            fill: #b0b5bf;
            margin-top: 5px;
            text-align: right;
          }
        }

        width: 100%;
        margin-top: 20px;
      }
    }
  }
}
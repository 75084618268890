@import "../../theme/variables.scss";

.navbar {
  z-index: 11;
  background-color: #ffffff;
  box-shadow: 0px 0.5px 0.5px #ececec;
  display: none;
  padding: 20px;
  @media only screen and (max-width: 992px) {
    display: block;
  }
  @media only screen and (max-width: 768px) {
    display: block;
  }
  .navbar-toggler:focus {
    box-shadow: none;
    z-index: 99;
  }

  .container-fluid {
    padding: 0;
   
    
    .logo-content {
      @media only screen and (max-width: 992px) {
        width: 10rem;
        img {
          width: 10rem;
        }
      }
    }
    .right {
      display: flex;
      gap: 32px;
      @media only screen and (max-width: 994px) {
        gap: 12px;
      }
      .btn {
        padding: 0;
        outline: none;
        &:focus {
          box-shadow: none;
        }
      }
      .auth-buttons {
        button {
          @media only screen and (max-width: 574px) {
            position: unset;
          }
          .dropdown {
            @media only screen and (max-width: 574px) {
              /*  right: -60px; */
              top: 85px;
              right: 20px;
              width: calc(100% - 40px);
            }
            &::after {
              @media only screen and (max-width: 574px) {
                right: 90px;
              }
            }
          }
        }
      }
    }
  }
  .offcanvas {
    padding: 20px;
  }
  .offcanvas-header {
    padding: 0;
    margin-bottom: 16px;
  }
  .offcanvas-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    .bottom {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }

  .items_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
    height: 100%;
    justify-content: center;
    position: relative;
    transition: 0.4s;

    .nav-item {
      width: 100%;
      height: 64px;
      padding: 8px 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .item {
      text-decoration: none;
      padding: 0.5em 1em;
      color: #818999;
      height: 100%;
      text-transform: capitalize;
      font-family: "Poppins-SemiBold";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;

      &.lang {
        display: inline-block;
        border: none;
        background-color: transparent;
        cursor: pointer;
      }

      &:hover {
        color: #818999;
        transition: all 0.3s ease-in-out;
      }

      &.active {
        color: #333333;
        transition: all 0.3s ease-in-out;

        &::before {
          display: none;
          content: "";
          position: absolute;
          width: 6px;
          height: 6px;
          border-radius: 3px;
          bottom: 0;
          background-color: $secondary-color;
        }
      }
    }
  }
}

.navbarWao__container {
  position: fixed;
  width: 100%;
  height: 104px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  top: 0;
  left: 0;
  box-shadow: 0px 0.5px 0.5px #ececec;
  z-index: 11;
  background-color: #ffffff;
  transition: 0.4s;
  @media only screen and (max-width: 992px) {
    display: none;
  }
  .logo-content {
    width: 10%;
  }
  .nav-items-content {
    display: flex;
    justify-content: flex-end;
    gap: 18px;
    list-style: none;
    text-align: center;
    height: 100%;
    position: relative;
    transition: 0.4s;
    min-width: 658px;
    width: 20%;
    @media only screen and (min-width: 992px) and (max-width: 1200px) {
      min-width: auto;
      width: 50%;
    }
    .item {
      text-decoration: none;
      /* padding: 0.5em 1em; */
      // width: 90px;
      margin: 0 10px;
      color: #818999;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      text-transform: capitalize;

      font-family: "Poppins-SemiBold";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;

      &.lang {
        border: none;
        background-color: transparent;
        cursor: pointer;
      }

      &:hover {
        color: #818999;
        transition: all 0.3s ease-in-out;
      }

      &.active {
        color: #204694;
        transition: all 0.3s ease-in-out;

        &::before {
          content: "";
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50px;
          bottom: -6px;
          background-color: $secondary-color;
        }
      }
    }
  }

  .nav-buttons-content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 36px;
    width: 30%;
    @media only screen and (min-width: 992px) and (max-width: 1200px) {
      width: 35%;
    }
  }
}

.auth-buttons {
  display: flex;
  gap: 16px;
  button,
  a {
    position: relative;
    height: 48px;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    border-radius: 30px;
    padding: 16px 20px;
    border: none;
    font-family: "Poppins";
  }
  button {
    &[data-variation="signup"] {
      background: #204694;
      color: white;

      &:hover {
        background: #163065;
      }

      &:active {
        background: #0f2247;
      }
      @media only screen and (max-width: 994px) {
        height: 35px;
        font-size: 11px;
        padding: 10px 11px;
      }
    }
    .dropdown {
      position: absolute;
      background: #ffffff;
      box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.12);
      filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.12));
      border-radius: 12px;
      color: #6a7381;
      width: 320px;
      height: 148px;
      right: 0;
      top: 65px;
      /* padding: 24px; */
      display: none;
      &.active {
        display: block;
      }
      &::after {
        content: "";
        position: absolute;
        z-index: -1;
        right: 30px;
        top: -10px;
        width: 39px;
        height: 39px;
        background: #ffffff;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      ul {
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 100%;
        height: 100%;
        padding: 0;
        
        li {
          list-style: none;

          a {
            text-decoration: none;
            color: #6a7381;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 52px;
            padding: 0 24px;
            cursor: pointer;
            border-radius: 0;
            &:hover {
              background: #eef3fb;
            }
          }
        }
      }
    }
  }
  a {
    text-decoration: none;
    &[data-variation="login"] {
      border: 2px solid #cddaf4;
      border-radius: 24px;
      color: #204694;
      width: 82px;
      height: 48px;
      background: white;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 16px 50px;
      &:hover {
        background: #cddaf4;
        border: 2px solid #cddaf4;
      }

      &:active {
        background: #afc4ee;
        border: 2px solid #afc4ee;
      }
    }
  }
  &.mobile {
    margin-bottom: 20px;
    width: 100%;
    a {
      width: 100%;
    }
  }
}

.lang-buttons-navbar {
  label {
    font-family: "Poppins-SemiBold";
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 22px;
    color: #9199a5;
    &.active {
      color: #204694;
    }
  }
  span {
    font-family: "Poppins-medium";
    font-size: 16px;
    color: #eeeff1;
    line-height: 22px;
  }
}

/*reponsive*/
@media only screen and (max-width: 1100px) {
  /* For mobile phones: */
  .container {
    justify-content: space-evenly;
    align-items: center;
  }

  .navbar {
    z-index: 11;
    background-color: #ffffff;
    box-shadow: 0px 0px 0px #ececec;

  }
}


.navbar {
  z-index: 11;
  background-color: #ffffff;
  box-shadow: 0px 0px 0px #ececec;

}


.loading-wrapper {
  margin-top: 15%;
  margin-bottom: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .title-loading {
    font-weight: 700;
    font-size: 48px;
    line-height: 124%;
    color: #303133;
    margin-bottom: 5%
  

  }
}

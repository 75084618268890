@import "../../theme/variables";
#scroll-phone {
  background-color: #333333;
  padding-bottom: 163vh;
  .outer {
    width: 100%;
    min-width: 861px;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .device {
    width: 100%;
    height: 100vh;
    display: flex;
    margin-right: 60px;
  }

  .phone {
    background: url("../../assets/icons/tutorial-component/slider-v2/general.svg")
      no-repeat;
    background-size: contain;
    margin-left: auto;
    width: 394px;
    height: 710px;

    &.phone0 {
      background: url("../../assets/icons/tutorial-component/slider-v2/general.svg")
        no-repeat;
      background-size: contain;
    }

    &.phone1 {
      background: url("../../assets/icons/tutorial-component/slider-v2/descarga2.svg")
        no-repeat;
      background-size: contain;
    }

    &.phone2 {
      background: url("../../assets/icons/tutorial-component/slider-v2/registrarse2.svg")
        no-repeat;
      background-size: contain;
    }

    &.phone3 {
      background: url("../../assets/icons/tutorial-component/slider-v2/login2.svg")
        no-repeat;
      background-size: contain;
    }

    &.phone4 {
      background: url("../../assets/icons/tutorial-component/slider-v2/recarga2.svg")
        no-repeat;
      background-size: contain;
    }
    &.phone5 {
      background: url("../../assets/icons/tutorial-component/slider-v2/zelle2.svg")
        no-repeat;
      background-size: contain;
    }
  }

  .text {
    margin: 500px 0 150px 0;
    width: 100%;
    height: 100vh;

    .title-scroll-down {
      opacity: 0;
      width: 27.8rem;
      padding-bottom: 11.8rem;

      .title {
        color: $secondary-color;
        margin: 0.9rem 0px;
      }
      .description {
        color: #fff;
        p {
          margin: 10px 0;
          max-width: 420px;
        }
      }

      &:last-child {
        // gives enough space at the bottom for the element to hit the scroll-end position
        padding-bottom: 70vh;
      }
    }
  }
}
